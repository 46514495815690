<template>
    <div class="ht-user-dropdown">
        <HtDropdown
            position="block-start"
            class="branding-color"
        >
            <template #toggle>
                <div class="mainbar-profile">
                    <HtAvatar
                        class="mainbar-profile-user"
                        :user="$Utils.getAvatarData(companyUserData)"
                        size="s"
                    />
                    <div class="mainbar-profile-user-name">
                        {{ companyUserData.firstname }}
                    </div>
                    <HtIcon
                        name="arrow-separate-vertical"
                    />
                </div>
            </template>
            <template
                #default="{toggleDropdown}"
            >
                <HtDropdownGroup
                    v-if="!isHolding"
                    divider
                >
                    <HtDropdownItem
                        :sup-text="companyUserData.email"
                        double-line
                        :avatar="$Utils.getAvatarData(companyUserData)"
                        :data-cy="'headbar-my-account'"
                        @click.native="goToProfile(toggleDropdown)"
                    >
                        {{ companyUserData.firstname }} {{ companyUserData.lastname }}
                    </HtDropdownItem>
                </HtDropdownGroup>
                <HtDropdownGroup divider>
                    <HtDropdownItem
                        v-if="companyList.length"
                        :data-cy="'headbar-switch-account'"
                        @click.native="openChangeAccountModal(toggleDropdown)"
                    >
                        <t>Change Account</t>
                    </HtDropdownItem>
                    <HtDropdownItem
                        v-if="!isHolding"
                        :data-cy="'headbar-settings'"
                        @click.native="goToAccount(toggleDropdown)"
                    >
                        <t>Settings</t>
                    </HtDropdownItem>
                    <HtDropdownItem
                        v-if="isHolding || shared.session.companyUser.isAdmin()"
                        :data-cy="'headbar-online-guide'"
                        @click.native="openGuide(toggleDropdown)"
                    >
                        <t>Online guide</t>
                    </HtDropdownItem>
                    <HtDropdownItem
                        v-if="isSupportedLinkEnabled && shared.session.company.support_link"
                        :data-cy="'headbar-contact-us'"
                        @click.native="openContactUs(toggleDropdown)"
                    >
                        <t>Support</t>
                    </HtDropdownItem>
                </HtDropdownGroup>
                <HtDropdownGroup>
                    <HtDropdownItem
                        :data-cy="'headbar-logout'"
                        @click.native="onLogout"
                    >
                        <t>Logout</t>
                    </HtDropdownItem>
                </HtDropdownGroup>
            </template>
        </HtDropdown>
        <HtModal
            v-if="companyList.length > 0"
            ref="modalSwitchAccount"
        >
            <template #header>
                <div class="modal-header">
                    <t>Change account</t>
                    <div class="subtitle">
                        <FontAwesomeLayers class="icon">
                            <span style="color:#BDF2E2;">
                                <FontAwesomeIcon
                                    icon="circle"
                                    class="icon-circle"
                                />
                            </span>
                            <span style="color:#099468;">
                                <FontAwesomeIcon
                                    :icon="'check'"
                                    transform="shrink-10"
                                    class="icon-second"
                                />
                            </span>
                        </FontAwesomeLayers>
                        <t :company="shared.session.company.name">
                            Logged in on {company}
                        </t>
                    </div>
                </div>
            </template>
            <div class="switch-account-modal">
                <div>
                    <t>Change company</t>
                    <div
                        v-for="company in childCompanies"
                        :key="company.id"
                        class="switch-account-modal-button"
                        @click="switchToAccount(company.company_id)"
                    >
                        <span>{{ company.company_name }}</span>
                        <FontAwesomeIcon
                            :icon="'arrow-right'"
                            style="width: 9px; height: 9px;"
                        />
                    </div>
                </div>
                <div>
                    <t>Connect</t>
                    <div
                        class="switch-account-modal-button"
                        @click="switchToAccount(getHoldingCompany.company_id)"
                    >
                        <span>{{ getHoldingCompany.company_name }}</span>
                        <FontAwesomeIcon
                            :icon="'arrow-right'"
                            style="width: 9px; height: 9px;"
                        />
                    </div>
                </div>
            </div>
        </HtModal>
    </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import Utils from '@/Utils';
import HtModal from '../../globals/HtModal.vue';

export default {
    name: 'HtMainBarUserDropdown',
    components: {
        HtModal,
    },

    shared: {
        session: {},
    },

    data() {
        return {
            isLoading: false,
        };
    },

    computed: {
        ...mapState('companies', [
            'companyList',
        ]),
        ...mapGetters('companies', [
            'getCompanyCustomize',
        ]),
        childCompanies() {
            return this.companyList.filter((line) => (!line.company_is_holding));
        },
        getHoldingCompany() {
            return this.companyList.find((line) => line.company_is_holding);
        },
        isHolding() {
            return this.shared.session.company.is_holding;
        },
        companyUserData() {
            return {
                firstname: this.shared.session.companyUser.firstname,
                lastname: this.shared.session.companyUser.lastname,
                image: this.shared.session.companyUser.image,
                email: this.shared.session.companyUser.email,
            };
        },
        isSupportedLinkEnabled() {
            const companyCustomize = this.getCompanyCustomize(this.shared.session.company.id);

            return (!companyCustomize)
                ? !!this.shared.session.company.company_customize.models.find(
                    (companyCustomize) => 'support_link_is_enabled' in companyCustomize.content
                        && !!companyCustomize.content.support_link_is_enabled,
                )
                : !!companyCustomize && companyCustomize.supportLinkIsEnabled;
        },
    },

    created() {
        this.$store.dispatch('companies/fetchCompanyList');
    },

    methods: {
        goToAccount(toggleDropdown) {
            toggleDropdown();
            this.$router.push({ name: 'Account' });
        },
        goToProfile(toggleDropdown) {
            toggleDropdown();
            this.$router.push({
                name: 'Profile',
                params: {
                    company_user_id: this.shared.session.companyUser.id,
                },
            });
        },
        openGuide(toggleDropdown) {
            toggleDropdown();
            window.open('https://support.heyteam.com/knowledge', '_blank');
        },
        openContactUs(toggleDropdown) {
            toggleDropdown();
            window.open(this.shared.session.company.support_link, '_blank');
        },
        onLogout() {
            if (this.isLoading === true) {
                return;
            }

            this.isLoading = true;
            this.$http.post('auth/logout')
                .then(() => {
                    this.$Auth.logOut();
                    this.$router.push({ name: 'Login' });
                }).finally(() => {
                    this.isLoading = false;
                });
        },
        openChangeAccountModal(toggleDropdown) {
            toggleDropdown();
            this.$refs.modalSwitchAccount.open();
        },
        switchToAccount(companyId) {
            if (this.isLoading === true) {
                return;
            }

            this.isLoading = true;
            Utils.autologin(companyId)
                .then((url) => {
                    url.searchParams.append('redirect', '/');
                    window.location = url.toString();
                }).finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.ht-user-dropdown {
    padding: 10px;
    transition: var(--transition);
    transition-property: padding-right, padding-left;
}

.ht-dropdown{
    ::v-deep .ht-dropdown-btn {
        padding: 16px;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: var(--radius);
            opacity: 0;
            background: currentColor;
            transform: scale(0.9);
            transition: var(--transition-s);
        }
        &:hover, &:focus {
            &::before {
                opacity: 0.08;
                transform: scale(1);
            }
        }
    }
    ::v-deep .ht-dropdown-menu {
        color: var(--text-primary);
    }
}

.mainbar-profile {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    .ht-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        color: var(--text-primary);
    }
}

.mainbar-profile-user {
    margin-right: 8px;
    flex-shrink: 0;
}

.mainbar-profile-user-name {
    width: 176px;
    text-align: left;
    padding-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: opacity var(--transition-s);
    @include ht-body;
    color: var(--text-primary);
}

.switch-account-modal {
    & > div {
        padding-top: 2.9rem;
        padding-left: 1.55rem;
        padding-right: 1.55rem;
        padding-bottom: 2.4rem;
        border-radius: 8px;
        background-color: #F5F7F8;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
    &-button {
        border: 1px solid #E5E6E6;
        background-color: white;
        margin: 0.4rem 0;
        height: 4.8rem;
        line-height: 1.7rem;
        padding: 1.55rem 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        font-weight: normal;
        font-size: 1.4rem;
    }
}

.modal-header {
    display:flex;
    flex-direction: column;
    font-size: 2.8rem;
    .subtitle {
        font-size: 1.4rem;
        font-weight: normal;
        display:flex;
        align-items: center;
        gap: 0.8rem;
        .icon svg{
            width: 1.8rem;
            height: 1.8rem;
        }
    }
}

</style>
