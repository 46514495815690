<template>
    <div class="profile-administrative">
        <div class="profile-administrative-header">
            <h3>{{ translate('Administrative') }}</h3>
            <div class="profile-administrative-header-btn">
                <HtButton
                    v-if="canSyncToHRIS"
                    v-tooltip="syncButtonTooltip"
                    :loading="isHrisLoading"
                    :disabled="syncButtonDisabled"
                    type="secondary"
                    class="ml-3 sync-hris-button btn-icon"
                    @click="syncToHRIS"
                >
                    <HtIcon
                        name="refresh-double"
                    />
                    <t>Request HRIS synchronization</t>
                </HtButton>
                <HtButton
                    v-if="isAllowedToDownload"
                    :loading="zipLoading"
                    type="secondary"
                    @click="onExport"
                >
                    <t>Download ZIP</t>
                </HtButton>
                <HtButton
                    v-if="canCreate"
                    v-tooltip.left="getDisabledReason"
                    class="add-btn"
                    :disabled="getActiveUserPrograms.length === 0"
                    @click="openCreateModal()"
                >
                    <t>Add</t>
                </HtButton>
            </div>
        </div>

        <div
            v-for="(userCategory, index) in companyUserRequirementCategoryArray"
            :key="`${userCategory.id}_${index}`"
            class="administrative-category"
        >
            <div class="administrative-category-header">
                <div class="administrative-title">
                    {{ getTranslatedName(userCategory) }}
                </div>
                <HtBadge
                    v-if="
                        userCategory.progress_status === 'to_complete'
                            || userCategory.progress_status === 'pending'
                    "
                    size="large"
                >
                    <HtIndicator status="error" />
                    <t>Incomplete</t>
                </HtBadge>
                <HtBadge
                    v-if="userCategory.progress_status === CompanyUserRequirementCategory.STATUS_NOT_CONCERNED"
                >
                    <t>Not concerned</t>
                </HtBadge>
                <HtBadge
                    v-else-if="userCategory.status === CompanyUserRequirementCategory.STATUS_ARCHIVED"
                >
                    <t>Archived</t>
                </HtBadge>
                <HtBadge
                    v-else-if="userCategory.display_survey && userCategory.progress_status === 'pending'"
                >
                    <t>Pending</t>
                </HtBadge>
                <HtBadge
                    v-else-if="
                        userCategory.display_survey && userCategory.progress_status !== 'done'
                    "
                >
                    <t>Via survey</t>
                </HtBadge>
                <HtButton
                    v-if="canEdit(userCategory) && !isArchives(userCategory)"
                    type="tertiary"
                    class="btn-icon"
                    @click="openEditModal(index)"
                >
                    <HtIcon
                        name="edit-pencil"
                        size="16"
                        stroke-width="2.5"
                    />
                    <t>Edit</t>
                </HtButton>
                <HtButton
                    v-if="hasArchives(userCategory)"
                    type="tertiary"
                    class="btn-icon"
                    @click="openShowArchivesModal(index)"
                >
                    <HtIcon
                        name="eye"
                        size="16"
                        stroke-width="2.5"
                    />
                    <t>See archives</t>
                </HtButton>
            </div>
            <div class="administrative-list">
                <div
                    v-for="(item, index) in companyUserRequirementGroupedByCategory[userCategory.id]"
                    :key="`${item.name}_${index}`"
                    class="administrative-item"
                >
                    <div class="item-name">
                        {{ getTranslatedName(item) }}
                    </div>
                    <div class="item-value">
                        <NewUserRequirementRead
                            :user-requirement="item"
                            :user-id="companyUser.id"
                            :user-category-status="userCategory.status"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="companyUserRequirementCategoryArray.length > 0"
            class="administrative-buttons"
        >
            <HtButton
                v-if="canSendDms"
                class="ml-3"
                @click="$refs.userRequirementDmsModal.open(companyUser.id)"
            >
                <t>DMS</t>
            </HtButton>
            <HtButton
                v-if="canTriggerRequirementTag"
                class="ml-3"
                @click="$refs.userRequirementByTagsModal.open(companyUser.id)"
            >
                <t>Send data</t>
            </HtButton>
        </div>

        <div
            v-if="companyUserRequirementCategoryArray.length === 0"
            class="empty-component"
        >
            <b>
                <t>No requirements needed or not enough permission rights</t>
            </b>
        </div>

        <Modalable
            v-if="getActiveUserPrograms.length > 0"
            ref="modalableCreate"
            :mode="2"
            class="modalable-1 medium"
        >
            <HtFormSelect
                v-if="getActiveUserPrograms.length > 1"
                :id="'choose-program'"
                v-model="chosenUserProgramId"
                :label="translate('Choose a program')"
                :name="'choose-program'"
                :options="getActiveUserPrograms"
                :show-optional="false"
                @input="reloadCollection"
            />

            <UserRequirementCategoryCreateModal
                v-if="getUserProgramId"
                :key="getUserProgramId"
                :company-user-id="companyUser.id"
                :company-user-program-id="getUserProgramId"
                @on-create="reloadCollection"
            />
        </Modalable>

        <NewUserRequirementCategoryEditModal
            v-if="currentUserRequirementCategory"
            ref="modalEdit"
            @on-save="reloadCollection"
            @on-delete="reloadCollection"
        />

        <UserRequirementCategoryShowArchivesModal
            v-if="currentUserRequirementCategory"
            ref="modalShowArchives"
        />

        <UserRequirementDmsModal ref="userRequirementDmsModal" />
        <UserRequirementByTagsModal ref="userRequirementByTagsModal" />
    </div>
</template>

<script>
import NewUserRequirementCategoryEditModal from '@/components/pages/onboarding/NewUserRequirementCategoryEditModal.vue';
import UserRequirementCategoryShowArchivesModal
    from '@/components/pages/onboarding/UserRequirementCategoryShowArchivesModal.vue';
import UserRequirementCategoryCreateModal from '@/components/pages/onboarding/UserRequirementCategoryCreateModal.vue';
import UserRequirementDmsModal from '@/components/pages/dashboard/modals/requirement/UserRequirementDmsModal.vue';
import CompanyUserRequirement from '@/models/CompanyUserRequirement';
import NewUserRequirementRead from '@/components/pages/onboarding/NewUserRequirementRead.vue';
import UserRequirementByTagsModal from '@/components/pages/dashboard/modals/requirement/UserRequirementByTagsModal.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import CompanyUser from '@/models/CompanyUser';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import DownloadUploadFileMixin from '@/components/mixins/DownloadUploadFileMixin';
import HtButton from '@/components/miscellaneous/HtButton.vue';
import CompanyUserRequirementCategory from '@/models/CompanyUserRequirementCategory';
import { showNotification } from '@/helpers/ui';
import { createHrisSyncValidator } from '@/validators/createHrisSyncValidator';

export default {
    name: 'NewUserRequirementProfile',
    components: {
        HtFormSelect,
        NewUserRequirementRead,
        UserRequirementCategoryCreateModal,
        NewUserRequirementCategoryEditModal,
        UserRequirementCategoryShowArchivesModal,
        UserRequirementDmsModal,
        UserRequirementByTagsModal,
        HtButton,
    },
    mixins: [
        DownloadUploadFileMixin,
    ],
    props: {
        companyUser: {
            type: CompanyUser,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
    },

    permissions: [
        'ModelCompanyUserRequirementCategory',
        'AbstractDocumentManagementSystem',
        'AbstractSyncProfileToHris',
    ],

    data() {
        return {
            zipLoading: false,
            isHrisLoading: false,
            showListIcons: {},
            buttonState: 'idle',
            currentUserRequirementCategory: null,
            companyUserRequirementCategoryArray: [],
            chosenUserProgramId: null,
        };
    },

    computed: {
        /** Usefull to access static const defined in this model */
        CompanyUserRequirementCategory() {
            return CompanyUserRequirementCategory;
        },
        getDisabledReason() {
            if (this.getActiveUserPrograms.length === 0) {
                return this.translate('Unable to add requirements because this user does not have any active program');
            }

            return null;
        },
        getActiveUserPrograms() {
            return this.companyUser.company_user_program.models
                .filter((userProgram) => userProgram.status === CompanyUserProgram.STATUS_ACTIVE)
                .map((userProgram) => ({
                    id: userProgram.id,
                    name: this.oldLocalize(userProgram.company_program.locales.models, 'name'),
                }));
        },
        getUserProgramId() {
            if (this.getActiveUserPrograms.length === 1) {
                return this.getActiveUserPrograms[0].id;
            }
            return this.chosenUserProgramId;
        },
        hasPermission() {
            return this.$canRead('AbstractDocumentManagementSystem');
        },

        canSendDms() {
            return this.hasPermission
                && this.shared.session.company.hasDms;
        },

        canTriggerRequirementTag() {
            return this.hasPermission
                && (this.$store.state.config.settings.triggers_requirements_tags ?? false);
        },

        companyUserRequirementGroupedByCategory() {
            const oCompanyUserRequirementByCategory = {};

            this.companyUserRequirementCategoryArray.forEach((category) => {
                const aCompanyUserRequirement = [];
                category.company_user_requirement_group.forEach((group) => {
                    group.company_user_requirement.forEach((requirement) => {
                        aCompanyUserRequirement.push(requirement);
                    });
                });
                oCompanyUserRequirementByCategory[category.id] = aCompanyUserRequirement;
            });

            return oCompanyUserRequirementByCategory;
        },

        canCreate() {
            return this.$canCreate('ModelCompanyUserRequirementCategory', {
                company_user_id: this.companyUser.id,
            }) && this.companyUserProgramId;
        },

        isAllowedToDownload() {
            return this.shared.session.can_onboardee_download_requirements;
        },

        hrisSyncValidator() {
            return createHrisSyncValidator({
                isHrisLoading: this.isHrisLoading,
                hasPermissionToSyncToHRIS: this.hasPermissionToSyncToHRIS,
                isAllAdministrativeFileCompleted: this.isAllAdministrativeFileCompleted,
                hrisSyncStatus: this.companyUser.hrisSyncStatus(),
            });
        },

        hasPermissionToSyncToHRIS() {
            return this.$canRead('AbstractSyncProfileToHris');
        },

        canSyncToHRIS() {
            return this.hasPermissionToSyncToHRIS;
        },

        isAllAdministrativeFileCompleted() {
            return this.companyUserRequirementCategoryArray.every((category) => (
                category.progress_status === CompanyUserRequirementCategory.STATUS_DONE
                || category.progress_status === CompanyUserRequirementCategory.STATUS_NOT_CONCERNED
            ));
        },

        syncButtonTooltip() {
            const failedValidation = this.hrisSyncValidator.getFirstFailedRule();
            return failedValidation?.message?.content ?? null;
        },

        syncButtonDisabled() {
            if (this.isHrisLoading) return false;
            return !this.hrisSyncValidator.isValid();
        },
    },

    created() {
        this.reloadCollection();
    },

    methods: {
        canEdit(userCategory) {
            return !userCategory.display_survey || userCategory?.userSurvey.task.status === 'done';
        },
        isArchives(userCategory) {
            return userCategory.status === 'archived' && userCategory.archived_at !== null;
        },
        hasArchives(userCategory) {
            return userCategory.archives_count > 0;
        },
        openEditModal(index) {
            this.currentUserRequirementCategory = this.companyUserRequirementCategoryArray[index];
            this.$nextTick(() => this.$refs.modalEdit.open(
                this.companyUser.id,
                this.currentUserRequirementCategory.id,
                this.getExecutorTaskFromRequirementCategory(this.currentUserRequirementCategory),
            ));
        },

        openShowArchivesModal(index) {
            this.currentUserRequirementCategory = this.companyUserRequirementCategoryArray[index];
            this.$nextTick(() => this.$refs.modalShowArchives.open(
                this.companyUser.id,
                this.currentUserRequirementCategory.id,
            ));
        },

        openCreateModal() {
            this.$refs.modalableCreate.setTitle(this.translate('Add requirements'));
            this.$refs.modalableCreate.open();
        },

        async onExport() {
            this.zipLoading = true;
            await this.downloadUserRequirementFiles(this.companyUser.id, true);
            this.zipLoading = false;
        },

        turnHoverEdit(hovering, index) {
            const requirement = this.companyUserRequirementCategoryArray[index];

            if (requirement && (requirement.status === CompanyUserRequirement.STATUS_DONE || requirement.display_survey === false)) {
                this.showListIcons[requirement.id] = hovering;
            }
        },

        reloadCollection() {
            this.$http.get(`enrollee/${this.companyUser.id}/requirements/profil`).then((response) => {
                this.companyUserRequirementCategoryArray = response.data.data;
                this.companyUserRequirementCategoryArray.forEach((userRequirement) => {
                    this.$set(this.showListIcons, userRequirement.id, false);
                });
            });
        },

        getExecutorTaskFromRequirementCategory(userRequirementCategory) {
            return userRequirementCategory.tasks.find(
                (t) => t.status === CompanyUserProgramTask.STATUS_PENDING && t.participants.find(
                    (p) => p.company_user_id === this.shared.session.companyUser.id,
                ),
            );
        },

        getTranslatedName(item) {
            if (item.is_heyteam) {
                return this.translate(item.name);
            }
            return item.name;
        },

        getTranslatedDescription(item) {
            if (item.is_heyteam) {
                return this.translate(item.description);
            }
            return item.description;
        },

        async syncToHRIS() {
            const failed = this.hrisSyncValidator.getFirstFailedRule();
            if (failed?.message) {
                showNotification(failed.message.content, failed.message.type);
                return;
            }

            try {
                this.isHrisLoading = true;
                await this.$http.post(`connector/feature/${this.companyUser.id}`);

                this.companyUser.get();

                showNotification(this.translate('Synchronization request sent successfully'), 'info');
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    showNotification(this.translate('You do not have the permission to send synchronization request'), 'error');
                    return;
                }

                showNotification(this.translate('Failed to send synchronization request'), 'error');
            } finally {
                this.isHrisLoading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped src="./UserRequirementProfile.scss"></style>
<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.administrative-category {
    padding: 20px;
    box-shadow: var(--shadow-1);
    border: 1px solid var(--border-primary);
    background-color: var(--fill-primary);
    border-radius: var(--radius-block);

    &:not(:last-child) {
        margin-bottom: 12px;
    }
}

.administrative-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.sync-button {
    position: relative;
}

.status-badge {
    margin-left: 8px;
    padding: 4px 8px;
    font-size: 0.85em;
    font-weight: bold;
    border-radius: 6px;
}

.status-badge.in_progress {
    background-color: var(--color-warning);
    color: white;
}

.status-badge.success {
    background-color: var(--color-success);
    color: white;
}

.status-badge.failed {
    background-color: var(--color-danger);
    color: white;
}

.profile-administrative-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    h3 {
        @include ht-heading-3;
        padding-right: 12px;
        margin-right: auto;
        margin-bottom: 12px;
    }
}

.profile-administrative-header-btn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;

    .ht-btn {
        margin-bottom: 12px;
    }
}

.administrative-category-header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
}

.administrative-title {
    @include ht-heading-4;
    margin-right: auto;
}

.btn-icon {
    ::v-deep .ht-button-inner {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

.administrative-item {
    display: flex;
    flex-direction: column;
    @include ht-body;

    &:not(:last-child) {
        margin-bottom: 12px;
    }
}

.item-name {
    color: var(--text-secondary)
}

.administrative-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

</style>
