<template>
    <UtilsTemplate
        :title="getTitle"
        :subtitle="getDescription"
        :clickable="false"
    >
        <template #header>
            <div class="date-icon">
                <div class="day">
                    {{ $Utils.moment().format("Do") }}
                </div>
                <div class="month">
                    {{ $Utils.moment().format("MMM") }}
                </div>
            </div>
        </template>
        <template #footer>
            <div class="footer-text">
                {{ getKeyDate }}
            </div>
        </template>
    </UtilsTemplate>
</template>

<script>
import UtilsTemplate from '../templates/UtilsTemplate.vue';

export default {
    name: 'KeyDateWidget',
    components: {
        UtilsTemplate,
    },
    computed: {
        summary() {
            const momentDate = this.$Utils.moment(this.$Utils.moment().format('YYYY-MM-DD'));
            const activePrograms = this.$store.getters['dashboard/actions/activeProgramsSummary'];
            const orderedPrograms = activePrograms.sort((a, b) => new Date(b.starts_at) - new Date(a.starts_at));
            const futurePrograms = orderedPrograms.filter((program) => momentDate.isSameOrBefore(program.starts_at));

            if (futurePrograms.length > 0) {
                return futurePrograms.reverse()[0];
            }

            return orderedPrograms[0];
        },
        getKeyDate() {
            const keyDates = this.$store.getters['config/getKeyDatesBySlug'];
            const keyDateName = this.translate(keyDates[this.summary.program_key_date_slug]?.name);
            const keyDateFormatted = this.$Utils.mysqlToDate(
                this.summary.starts_at,
                this.shared.session.companyUser.company_language.key === 'fr'
                    ? 'DD/MM/YYYY'
                    : 'MM/DD/YYYY',
            );
            return `${keyDateName}: ${keyDateFormatted}`;
        },
        diffKeyDateNow() {
            return this.$Utils.moment(this.summary.starts_at).diff(this.$Utils.moment().startOf('day'), 'days');
        },
        getTitle() {
            return this.translate('First day | {count} day | {count} days', {
                count: Math.abs(this.diffKeyDateNow),
            });
        },
        getDescription() {
            if (this.diffKeyDateNow === 0) {
                return this.translate('Start of your program "{name}"', { name: this.summary.name });
            } if (this.diffKeyDateNow > 0) {
                return this.translate('Before the start of your program "{name}"', { name: this.summary.name });
            }
            return this.translate('Since the start of your program "{name}"', { name: this.summary.name });
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";
.date-icon {
    height: 56px;
    width: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $neutral-100;
    border: 1px solid $neutral-300;
    border-top: 8px solid $primary;
    border-radius: 8px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 100%;

    .day{
        font-size: 20px;
    }
    .month{
        font-size: 14px;
    }
}
.footer-text {
    font-size: 14px;
    font-weight: 600;
}
</style>
