<template>
    <div>
        <h2 class="actions-title">
            My actions
        </h2>
        <NewPageWithSidebar>
            <template #sidebar>
                <NewHtPageSidebar
                    v-if="userPrograms.length > 1"
                    :pages="userPrograms"
                />
            </template>

            <template>
                <router-view v-if="isLoaded" />
            </template>
        </NewPageWithSidebar>
    </div>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import HtProgramType from '@/models/HtProgramType';
import CompanyUserProgramCollection from '@/models/CompanyUserProgramCollection';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import NewHtPageSidebar from '@/components/globals/NewHtPageSidebar.vue';
import NewPageWithSidebar from '@/layouts/NewPageWithSidebar.vue';
import { isEmpty } from '@/helpers/_';

export default {
    name: 'PagesDashboard',
    components: {
        NewHtPageSidebar,
        NewPageWithSidebar,
    },
    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: {},
            },
        },
    },

    data() {
        return {
            userPrograms: [],
            isLoaded: false,
            companyUserProgramCollection: null,
        };
    },

    watch: {
        $route() {
            this.redirect();
        },
    },

    async created() {
        await this.setUserProgramTabs();

        this.isLoaded = true;
        this.redirect();
    },

    methods: {
        async setUserProgramTabs() {
            const userProgramTabs = await this.getUserPrograms();
            const isAllowedToSeeEveryActions = await this.isAllowedToSeeEveryActions(userProgramTabs);
            if (isAllowedToSeeEveryActions) {
                this.addTabByNameAndLabelFromStart(
                    userProgramTabs,
                    'AllActions',
                    'All actions',
                );
            }

            if (isEmpty(userProgramTabs)) {
                this.redirectTo('Dashboard');
            }

            this.setUserProgramsByTabs(userProgramTabs);
        },
        makeUserProgramsBuilder() {
            return new CompanyUserProgramCollection([
                'id',
                'company_user_id',
                'title',
                'description',
                'type',
                'status',
                'timelines',
                'enrollee_completion',
                'participants_completion',
                'task_count_late',
                'company_program_id',
            ])
                .with({
                    mainKeyDate: (query) => {
                        query.select(['id', 'starts_at']);
                    },
                    companyProgram: (query) => {
                        query.select(['id']).with({
                            locales: (query) => {
                                query.select(['id', 'language_key', 'name']);
                            },
                        });
                    },
                })
                .where([
                    ['company_user_id', '=', this.shared.session.companyUser.id],
                ])
                .whereIn([
                    ['status', [CompanyUserProgram.STATUS_ACTIVE, CompanyUserProgram.STATUS_COMPLETED]],
                ])
                .whereHas({
                    companyProgram: (query) => {
                        query.whereHas({
                            htProgramType: (query) => {
                                query.where([
                                    ['slug', '!=', HtProgramType.SLUG_DEFAULT],
                                ]);
                            },
                        });
                    },
                });
        },
        async getUserPrograms() {
            try {
                this.companyUserProgramCollection = await this.makeUserProgramsBuilder();
                await this.companyUserProgramCollection.get();

                const userPrograms = this.companyUserProgramCollection.$.models.map((userProgram) => ({
                    label: userProgram.company_program.name_translated,
                    params: { program_id: userProgram.id },
                    name: 'ProgramActions',
                }));

                return userPrograms;
            } catch (error) {
                console.log('Unable to retrieve user programs: ', error);
                this.$router.push({ name: 'Dashboard' });
            }
        },
        async fetchUsersByCurrentActions() {
            const usersByCurrentActions = await this.$store.dispatch('tasks/fetchCurrentUsersAndEmployees')
                .then((result) => result.data.data);
            return usersByCurrentActions;
        },
        async hasSupervisorActions() {
            const usersByCurrentActions = await this.fetchUsersByCurrentActions();
            return !isEmpty(usersByCurrentActions)
                && usersByCurrentActions.length > 1
                && usersByCurrentActions[0].id !== this.shared.session.companyUser.id;
        },
        isAllowedToSeeEveryActions(userProgramTabs) {
            return this.hasSupervisorActions() || userProgramTabs.length > 1;
        },
        addTabByNameAndLabelFromStart(userProgramTabs, name, label) {
            userProgramTabs.unshift({
                name,
                label: this.translate(label),
            });
        },
        setUserProgramsByTabs(userProgramTabs) {
            this.userPrograms = userProgramTabs;
        },
        redirectTo(name) {
            this.$router.push({
                name,
            });
        },
        redirect() {
            if (this.$route.name !== 'Actions') return;
            if (this.userPrograms.length === 2) {
                this.$router.push(this.userPrograms[1]);
            } else {
                this.$router.push(this.userPrograms[0]);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.actions-title {
    margin-bottom: 20px;
    @include ht-heading-2;
}
</style>
