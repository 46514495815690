<template>
    <div>
        <HtFormCard
            v-if="isInviteNotification"
        >
            <HtFormSwitch
                id="send-email"
                v-model="item.notifications.models[indexNotif].templates.models[indexTemplate].is_enabled"
                :label="translate('Enable email sending')"
                :off-label="translate('No')"
                :on-label="translate('Yes')"
                :name="'send-email'"
                :show-optional="false"
            />

            <HtToast
                v-if="item.notifications.models[indexNotif].templates.models[indexTemplate].is_enabled && !!notificationDescription"
                type="info"
                :message="notificationDescription"
            />
        </HtFormCard>

        <HtFormCard class="mt-5">
            <HtFormInput
                :id="'subject'"
                v-model="item.notifications.models[indexNotif].templates.models[indexTemplate].locales_by_key[language.key].subject"
                v-validate.disable="'required'"
                :name="'subject'"
                :label="translate('Subject')"
                :data-vv-as="translate('E-mail subject')"
                :variables="getVariablesOfField(item.notifications.models[indexNotif].variables, 'subject')"
            />
            <HtFormEditor
                :id="'content'"
                v-model="item.notifications.models[indexNotif].templates.models[indexTemplate].locales_by_key[language.key].content"
                v-validate.disable="'required'"
                :name="'content'"
                :label="translate('Content')"
                :variables="getVariablesOfField(item.notifications.models[indexNotif].variables, 'content')"
            />
            <HtFormSwitch
                :id="'fileJoined'"
                v-model="withAttachment"
                :label="translate('Join a file')"
                :off-label="translate('No')"
                :on-label="translate('Yes')"
                :name="'fileJoined'"
                :show-optional="false"
            />
            <UploadBar
                v-if="withAttachment"
                v-model="item.notifications.models[indexNotif].attachment"
                :media="['office', 'pdf', 'image']"
                :with-trash="false"
            />
        </HtFormCard>
    </div>
</template>

<script>
import HtFormCard from '@/components/globals/HtFormCard.vue';
import HtFormEditor from '@/components/globals/HtFormEditor.vue';
import CompanyProgram from '@/models/CompanyProgram';
import UploadBar from '@/components/globals/UploadBar/UploadBar.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import NotificationVariableMixin from '@/mixins/NotificationVariableMixin';
import CompanyProgramNotificationTemplate from '@/models/CompanyProgramNotificationTemplate';
import HtToast from '@/components/globals/HtToast.vue';

export default {
    name: 'EmailForm',
    components: {
        HtToast,
        HtFormCard,
        HtFormEditor,
        UploadBar,
        HtFormSwitch,
    },
    mixins: [
        NotificationVariableMixin,
    ],
    props: {
        item: {
            type: CompanyProgram,
            required: true,
        },
        indexNotif: {
            type: Number,
            required: true,
        },
        indexTemplate: {
            type: Number,
            required: true,
        },
        language: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            /**
             * avec une pièce jointe
             */
            withAttachment: null,
        };
    },
    computed: {
        notificationDescription() {
            const descriptions = {
                inviteOnProgram: this.translate('The invitation to program participants will only be sent if they have actions to realize upon program’s creation'),
            };

            const slug = this.item.notifications.models[this.indexNotif].template_slug;

            return descriptions[slug] || null;
        },
        isInviteNotification() {
            const inviteNotifications = [
                CompanyProgramNotificationTemplate.TEMPLATE_INVITE,
                CompanyProgramNotificationTemplate.TEMPLATE_INVITE_ON_PROGRAM,
            ];
            return inviteNotifications.includes(this.item.notifications.models[this.indexNotif].template_slug);
        },
    },
    watch: {
        withAttachment(newValue) {
            if (newValue === false) {
                this.item.notifications.models[this.indexNotif].attachment = null;
                this.item.notifications.models[this.indexNotif].company_file_id = null;
            }
        },
        /**
         * refresh withAttachmentValue when changing from invite to inviteOnProgram
         */
        indexNotif: {
            immediate: true,
            handler() {
                this.withAttachment = Boolean(this.item.notifications.models[this.indexNotif].attachment);
                this.setContentFieldsToEmptyStringIfNull();
            },
        },

        language: {
            immediate: true,
            handler() {
                this.setContentFieldsToEmptyStringIfNull();
            },
        },
    },
    methods: {
        setContentFieldsToEmptyStringIfNull() {
            if (this.item.notifications.models[this.indexNotif].templates.models[this.indexTemplate].locales_by_key[this.language.key].subject === null) {
                this.item.notifications.models[this.indexNotif].templates.models[this.indexTemplate].locales_by_key[this.language.key].subject = '';
            }

            if (this.item.notifications.models[this.indexNotif].templates.models[this.indexTemplate].locales_by_key[this.language.key].content === null) {
                this.item.notifications.models[this.indexNotif].templates.models[this.indexTemplate].locales_by_key[this.language.key].content = '';
            }
        },
    },

};
</script>
