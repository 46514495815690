import orderBy from 'lodash.orderby';
import groupBy from 'lodash.groupby';
import moment from 'moment/moment';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import I18n from '../../modules/i18n/I18n';

export default class TimelineHelper {
    static otherTimelinePeriods = {};

    static newOrderForOtherTimelinePeriods = 0;

    static newIndexForOtherTimelinePeriods = 0;

    static otherColors = [
        '#41742F', '#45A68D', '#1788A0',
        '#536D93', '#583881', '#9E6089',
        '#B84B4B', '#C96F1C', '#D9C618',
        '#A06F4D', '#94834B',
    ];

    /**
     * @param {string} unit
     * @param {number} number
     * @returns {string}
     */
    static getPrefixLabel(unit, number) {
        const labels = {
            days: I18n.translate('{count} day | {count} days', { count: Math.abs(number) }),
            weeks: I18n.translate('{count} week | {count} weeks', { count: Math.abs(number) }),
            months: I18n.translate('{count} month | {count} months', { count: Math.abs(number) }),
        };

        return labels[unit];
    }

    /**
     * @param {number} number
     * @param {string} keyDate
     * @returns {string}
     */
    static getSuffixLabel(number, keyDate) {
        return number < 0
            ? I18n.translate('before "{keyDate}"', { keyDate })
            : I18n.translate('after "{keyDate}"', { keyDate });
    }

    /**
     * @param {number} offsetAvailabilityNumber
     * @param {string} offsetAvailabilityUnit
     * @param {string} keyDate
     * @returns {string}
     */
    static getAvailabilityPeriodLabel(offsetAvailabilityNumber, offsetAvailabilityUnit, keyDate) {
        if (offsetAvailabilityUnit === CompanyResourceProgram.UNIT_IMMEDIATELY) {
            return I18n.translate('Immediately');
        }

        return `${this.getPrefixLabel(offsetAvailabilityUnit, offsetAvailabilityNumber)} ${this.getSuffixLabel(offsetAvailabilityNumber, keyDate)}`;
    }

    /**
     * @param {string} offsetAvailabilityUnit
     * @param {number} offsetEndNumber
     * @param {string} offsetEndUnit
     * @param {string} keyDate
     * @returns {string}
     */
    static getEndPeriodLabel(offsetAvailabilityUnit, offsetEndNumber, offsetEndUnit, keyDate) {
        const keyDateLabel = offsetAvailabilityUnit === CompanyResourceProgram.UNIT_IMMEDIATELY
            ? keyDate
            : I18n.translate('Availability date');

        return `${this.getPrefixLabel(offsetEndUnit, offsetEndNumber)} ${this.getSuffixLabel(offsetEndNumber, keyDateLabel)}`;
    }

    /**
     * @param {import('moment/moment.js').Moment} mainKeyDateStartsAt
     * @param {import('moment/moment.js').Moment} datetimeEnd
     * @returns {{label: string, color: string, order: number}}
     */
    static getTaskPeriod(mainKeyDateStartsAt, datetimeEnd) {
        if (datetimeEnd.isBefore(mainKeyDateStartsAt, 'day')) {
            return {
                title: 'Preparing for your arrival',
                simpleLabel: 'Before my arrival',
                label: 'Before {key_date_name}',
                color: '#FF6F59',
                order: 1,
            };
        }
        if (datetimeEnd.isSame(mainKeyDateStartsAt, 'day')) {
            return {
                title: 'You arrive today',
                simpleLabel: 'First day',
                label: 'The first day',
                color: '#F4AE3E',
                order: 2,
            };
        }
        if (datetimeEnd.diff(mainKeyDateStartsAt, 'day') < 7) {
            return {
                title: 'You are in your first week',
                simpleLabel: 'First week',
                label: 'The first week',
                color: '#6BD2DE',
                order: 3,
            };
        }
        if (datetimeEnd.diff(mainKeyDateStartsAt, 'month') < 1) {
            return {
                title: 'You are in your first month',
                simpleLabel: 'First month',
                label: 'The first month',
                color: '#9373F7',
                order: 4,
            };
        }

        return {
            title: "You've been here for more than a month",
            simpleLabel: 'More than a month after',
            label: 'More than a month after',
            color: '#5DB7F9',
            order: 5,
        };
    }

    static groupTasksByPeriod(tasks, mainKeyDateStartsAt) {
        tasks.forEach((task) => {
            Object.defineProperty(task, 'period', {
                value: this.getTaskPeriod(mainKeyDateStartsAt, moment(task.datetime_end)),
            });
        });
        return orderBy(groupBy(tasks, 'period.order'), (group) => group[0].period.order);
    }

    static getOtherPeriod(label) {
        if (this.otherTimelinePeriods[label] === undefined) {
            this.otherTimelinePeriods[label] = {
                label,
                order: 1000000 + (++this.newOrderForOtherTimelinePeriods),
                color: this.otherColors[this.newIndexForOtherTimelinePeriods],
            };

            this.newIndexForOtherTimelinePeriods = (this.newIndexForOtherTimelinePeriods + 1) % this.otherColors.length;
        }

        return this.otherTimelinePeriods[label];
    }
}
