export default class PermissionPlugin {
    // Liste des types
    static TYPE_CREATE = 'create';

    static TYPE_READ = 'read';

    static TYPE_UPDATE = 'update';

    static TYPE_DELETE = 'delete';

    static TYPE_VALIDATE = 'validate';

    /**
     * Installation du plugin Vue
     */
    static install(Vue) {
        function compiledPermissions(permissions) {
            if (typeof permissions === 'function') {
                return permissions.call(this);
            }

            return permissions;
        }

        Vue.mixin({

            computed: {
                permissionsLoaded() {
                    if (this.$options.permissions) {
                        return this.$store.getters['permissions/getPermissionsLoaded'](compiledPermissions.call(this, this.$options.permissions));
                    }

                    return false;
                },
            },
            created() {
                const { permissions } = this.$options;

                if (permissions) {
                    this.$store.dispatch('permissions/load', { names: compiledPermissions.call(this, permissions) });
                }
            },

            methods: {
                $can(name, options = {}, type = PermissionPlugin.TYPE_READ) {
                    this.$store.dispatch('permissions/load', { names: [name] });

                    return this.$store.getters['permissions/can'](name, options, type);
                },

                $canCreate(name, options = {}) {
                    return this.$can(name, options, PermissionPlugin.TYPE_CREATE);
                },

                $canRead(name, options = {}) {
                    return this.$can(name, options, PermissionPlugin.TYPE_READ);
                },

                $canUpdate(name, options = {}) {
                    return this.$can(name, options, PermissionPlugin.TYPE_UPDATE);
                },

                $canDelete(name, options = {}) {
                    return this.$can(name, options, PermissionPlugin.TYPE_DELETE);
                },

                $canValidate(name, options = {}) {
                    return this.$can(name, options, PermissionPlugin.TYPE_VALIDATE);
                },

                /**
                 * @param {Array<String>} names
                 * @returns {Promise<any>}
                 */
                $loadPermissions(names) {
                    return this.$store.dispatch(
                        'permissions/load',
                        { names },
                    );
                },

                $refreshPermissions(names, forceCacheRefresh) {
                    return this.$store.dispatch('permissions/refresh', { names, forceCacheRefresh });
                },

                $deletePermissions(names, forceCacheRefresh) {
                    return this.$store.dispatch('permissions/delete', { names, forceCacheRefresh });
                },
            },
        });
    }
}
