/**
 * @typedef {Object} ValidationMessage
 * @property {'info' | 'warning' | 'error'} type
 * @property {string} content
 */

/**
 * @typedef {Object} ValidationRule
 * @property {() => boolean} condition
 * @property {ValidationMessage} [message]
 */

export default class Validator {
    /**
     * @type {ValidationRule[]}
     */
    rules = [];

    /**
     * @param {ValidationRule[]} rules
     */
    constructor(rules) {
        this.rules = rules;
    }

    /**
     * @returns {ValidationRule}
     */
    getFirstFailedRule() {
        return this.rules.find((rule) => rule.condition());
    }

    /**
     * @returns {ValidationRule[]}
     */
    getAllFailedRules() {
        return this.rules.filter((rule) => rule.condition());
    }

    /**
     * @returns {boolean}
     */
    isValid() {
        return !this.getFirstFailedRule();
    }
}
