import Validator from '@/core/Validator';
import I18n from '@/modules/i18n/I18n';
import CompanyUser from '@/models/CompanyUser';

/**
 * @typedef {Object} HrisSyncContext
 * @property {boolean} isHrisLoading
 * @property {boolean} hasPermissionToSyncToHRIS
 * @property {boolean} isAllAdministrativeFileCompleted
 * @property {string} hrisSyncStatus
 */

/**
 * @param {HrisSyncContext} context
 * @returns {Validator}
 */
export function createHrisSyncValidator({
    isHrisLoading,
    hasPermissionToSyncToHRIS,
    isAllAdministrativeFileCompleted,
    hrisSyncStatus,
}) {
    const rules = [
        {
            condition: () => isHrisLoading,
        },
        {
            condition: () => !hasPermissionToSyncToHRIS,
            message: {
                type: 'warning',
                content: I18n.translate('You do not have permission to synchronize to HRIS'),
            },
        },
        {
            condition: () => !isAllAdministrativeFileCompleted,
            message: {
                type: 'warning',
                content: I18n.translate('You must complete all administrative documents before sending them'),
            },
        },
        {
            condition: () => hrisSyncStatus === CompanyUser.HRIS_SYNC_IN_PROGRESS_STATUS,
            message: {
                type: 'info',
                content: I18n.translate('A synchronization is already in progress'),
            },
        },
        {
            condition: () => hrisSyncStatus === CompanyUser.HRIS_SYNC_SUCCESS_STATUS,
            message: {
                type: 'info',
                content: I18n.translate('The profile was already synchronized successfully'),
            },
        },
    ];

    return new Validator(rules);
}
