<template>
    <div class="general-loading-modal-container animation">
        <div class="animation-title">
            <t>Please wait</t>...
        </div>
        <p><t>{{ message }}</t></p>
        <div
            class="animation-bar"
            :style="`width:${total_bar_size}em;`"
        >
            <div
                class="inside-bg branding-bg"
                :class="{completed: is_animation_done}"
                :style="'width: '+ animation_progress + 'em;'"
                @transitionend="handleProgressBarTransitionEnd"
            />
        </div>
    </div>
</template>

<script>
import { sleep } from '@/helpers/time';

export default {
    name: 'GenericLoadingModal',
    props: {
        message: {
            type: String,
            required: true,
        },
        isThePageShouldBeReloaded: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            is_animation_done: false,
            total_bar_size: 20,
            animation_progress: 0,
        };
    },
    created() {
        this.progressBarAnimate();
    },
    methods: {
        /**
         * @returns {Promise<void>}
         */
        async progressBarAnimate() {
            await new Promise((resolve) => {
                const interval = setInterval(() => {
                    /**
                     * todo: In order not to break all the modals,
                     * we need to leave this part for the moment,
                     * which should be discussed by the team in order to be refactored.
                     * In fact, it's no longer useful in certain cases, such as the `QuickActionsModal` modal.
                     */
                    if (this.isTheAnimationProgressBarCompleted()) {
                        if (this.isThePageShouldBeReloaded) {
                            sleep(1000).then(() => window.location.reload());
                        }

                        resolve();
                        return;
                    }

                    const updateAnimationProgressBarRatio = (this.animation_progress + 1) * 2;

                    if (this.shouldUpdateTheAnimationProgressBar(updateAnimationProgressBarRatio)) {
                        this.updateTheAnimationProgressBar(updateAnimationProgressBarRatio);
                    } else {
                        clearInterval(interval);
                        resolve();
                    }
                }, 300);
            });

            this.completeTheAnimationProgressBar();
        },

        /**
         * @param {Number} updateAnimationProgressBarRatio
         * @returns {Boolean}
         */
        shouldUpdateTheAnimationProgressBar(updateAnimationProgressBarRatio) {
            return this.animation_progress + updateAnimationProgressBarRatio <= this.total_bar_size;
        },

        /**
         * note: To prevent bar to exceed container size
         * @param {Number} updateAnimationProgressBarRatio
         * @returns {void}
         */
        updateTheAnimationProgressBar(updateAnimationProgressBarRatio) {
            this.animation_progress += updateAnimationProgressBarRatio;
        },

        /**
         * @returns {void}
         */
        completeTheAnimationProgressBar() {
            this.animation_progress = this.total_bar_size;
            this.is_animation_done = true;
        },

        /**
         * @returns {Boolean}
         */
        isTheAnimationProgressBarCompleted() {
            return this.animation_progress >= this.total_bar_size;
        },

        /**
         * @returns {void}
         */
        handleProgressBarTransitionEnd() {
            this.$parent.$emit('is-animation-done', this.is_animation_done);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.animation {
    max-width: 430px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 300ms;
    margin: 0 auto 20px;

    &-title {
        font-size: 2.2rem;
        font-weight: bold;
        margin: 40px 0 15px;
    }

    &-bar {
        border-radius: 10px;
        height: 0.4em;
        margin-top: 2em;
        margin-bottom: 1em;

        .completed {
            background: $semantic-success;
        }
    }
}
</style>
