<template>
    <div
        v-if="type === 'rounded'"
        class="
            button
            button-x
            branding-bg
            branding-bg-active
            branding-bg-darken-hover
            branding-border-color
            branding-border-color-darken-hover
        "
        :class="state"
        :data-cy="cypress"
        @click="onClick"
    >
        <div class="label">
            <slot />
        </div>
    </div>
    <div
        v-else-if="type === 'clear'"
        class="button-y button-long branding-color branding-border-color"
        :class="state"
        :data-cy="cypress"
        @click="onClick"
    >
        <div class="label">
            <slot />
        </div>
    </div>
    <div v-else-if="type === 'flat'">
        <div
            class="validate-footer wide fixed-bottom third-quarter"
            :style="customStyle"
        >
            <div
                v-if="hasBack"
                class="btn-back"
                @click="onClickBack"
            >
                <IconBack />
            </div>
            <div
                class="btn-next"
                :class="state"
                @click="onClick"
            >
                <div class="label">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CypressMixin from '@/mixins/CypressMixin';

export default {
    mixins: [
        CypressMixin,
    ],

    props: {
        options: { type: Object, default: () => ({}) },
        state: { type: String, default: 'idle' },
        type: { type: String, default: 'rounded' },
        hasBack: { type: Boolean, default: false },
        customStyle: { type: Object, default: () => ({}) },
    },

    data() {
        return {
            // Default configs
            configs: {},
        };
    },

    created() {
        this.configs = { ...this.configs, ...this.options };
    },

    methods: {
        onClickBack(e) {
            this.$emit('clickBack', e);
        },

        onClick(e) {
            if (this.state === 'idle') this.$emit('click', e);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.third-quarter {
    width: calc(100% - 340px);
    margin-left: calc(100% - calc(100% - 340px));
}

.button-x {
    display: inline-block;
    padding: 12px 18px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;

    text-align: center;
    cursor: pointer;
    font-size: 1.4rem;
    font-family: $lato-bold;
    body:not(.old-ui) &{
        font-family: var(--poppins);
        font-weight: 600;
    }
    // font-weight: normal;

    background-position: center;
    transition: ease background 0.3s;

    &:hover{
        transform: scale(0.95);
    };

    .label{
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }
    &.small {
        padding: 5px 20px;
    }

    &.small{
        padding: 6px 25px;
    }

    &.no-margin-left {
        margin-left: 0;
    }

    &.long {
        padding: 1.2rem 4.8rem;
    }

    // COLORS
    &.negative {
        background: #fff;
        border-color: $grey-1;
        color: #342E37;

        &.loading {
            &:before {
                border: 1px solid #000;
                border-top-color: transparent;
                border-bottom-color: transparent;
            }
        }
    }

    &.inner-box {
        box-sizing: border-box;
        background: none;
    }

    &.black {
        background: $black;
        // border: 1px solid #342E37;
        color: #fff;

        &:hover {
            background: $black-darker;
        }

        &:active {
            background: $black;
        }
    }

    &.red {
        background: $primary;
        color: #fff;

        &:hover {
            background: $primary-darker;
        }

        &:active {
            background: $primary;
        }
    }

    &.green {
        background-color: $color-green;

        &:hover{
            background: $color-green-darker;
        }
        &:active {
            background: $color-green;
        }
    }

    &.yellow {
        background-color: $color-yellow;

        &:hover{
            background: $color-yellow-darker;
        }
        &:active {
            background-color: $color-yellow;
        }
    }
}

.button-y {
    display: inline-block;
    padding: 1.2rem 2.4rem;

    text-align: center;
    cursor: pointer;
    font-size: 1.6rem;
    font-family: $lato;
    font-weight: bold;
    body:not(.old-ui) &{
        font-family: var(--poppins);
        font-weight: 600;
    }
    line-height: 1.9rem;
    transition: 0.3s ease-in-out;

    background-position: center;
    transition: ease background 0.3s;

    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 100rem;

    &:hover{
        transform: scale(0.95);
    };
}

@-webkit-keyframes ld {
    0%   { transform: rotate(0deg) scale(1); }
    50%  { transform: rotate(180deg) scale(1.1); }
    100% { transform: rotate(360deg) scale(1); }
}
@-moz-keyframes ld {
    0%   { transform: rotate(0deg) scale(1); }
    50%  { transform: rotate(180deg) scale(1.1); }
    100% { transform: rotate(360deg) scale(1); }
}
@-o-keyframes ld {
    0%   { transform: rotate(0deg) scale(1); }
    50%  { transform: rotate(180deg) scale(1.1); }
    100% { transform: rotate(360deg) scale(1); }
}
@keyframes ld {
    0%   { transform: rotate(0deg) scale(1); }
    50%  { transform: rotate(180deg) scale(1.1); }
    100% { transform: rotate(360deg) scale(1); }
}

.disabled {
    position: relative;
    opacity: .8;
    background-color: #F8F8F8 !important;
    color: #342E37 !important;
    border: none;
}

.disabled:hover,
.disabled:active,
.disabled:focus {
    transform: none !important;
    cursor: default;
}

.loading {
    position: relative;
    opacity: .8;
    color: transparent !important;
    text-shadow: none !important;

    &:hover,
    &:active,
    &:focus {
        color: transparent;
        outline: none !important;
        box-shadow: none;
    }

    &:before {
        content: '';

        display: inline-block;

        position: absolute;
        background: transparent;
        border: 1px solid #fff;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-radius: 50%;
        cursor: pointer;
        //pointer-events: all;

        box-sizing: border-box;

        top: 50%;
        left: 50%;
        margin-top: -12px;
        margin-left: -12px;

        width: 24px;
        height: 24px;

        -webkit-animation: ld 1s ease-in-out infinite;
        -moz-animation:    ld 1s ease-in-out infinite;
        -o-animation:      ld 1s ease-in-out infinite;
        animation:         ld 1s ease-in-out infinite;
    }
}
</style>
