<template>
    <div>
        <ActionProgramDetail
            v-if="companyProgramId"
            :company-user-id="companyUserId"
            :company-user-program-id="companyProgramId"
        />
    </div>
</template>
<script>
import CompanyUser from '@/models/CompanyUser';
import ActionProgramDetail from '@/components/pages/actions/program/ActionProgramDetail.vue';

export default {
    name: 'ProgramActions',
    components: {
        ActionProgramDetail,
    },
    props: {
        companyProgramId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            program: {},
        };
    },
    computed: {
        companyUserId() {
            return this.shared.session.companyUser.id;
        },
    },
    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: {},
            },
        },
    },
};
</script>
