<template>
    <div>
        <HtFormSelector
            :id="'hexapost_form_selector'"
            v-model="hexapost"
            :name="'hexapost_form_selector'"
            :class="'hexapost_form_selector'"
            :disabled="disabled"
            :options="options"
            :show-optional="false"
            is-single
            specific-key="id"
            @onSelect="onSelectCity"
            @update:searchValue="toDebounce"
        />
        <small className="hexapost-help">{{ getHelpText() }}</small>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import get from 'lodash.get';
import Notifier from '@/Notifier';
import UserRequirementEditMixin from './UserRequirementEditMixin';

export default {
    name: 'UserRequirementHexapostEdit',

    components: {
        HtFormSelector,
    },

    mixins: [UserRequirementEditMixin],

    inject: ['$validator'],

    props: {
        index: {
            type: Number,
            default: 0,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            options: [],
            disabled: false,
            hexapost: null,
            toDebounce: debounce((value) => this.searchValue(value), 700),
        };
    },

    computed: {
        getSelectedResponse() {
            if (this.companyUserRequirement.value) {
                return {
                    id: this.companyUserRequirement.value.key,
                    name: this.companyUserRequirement.value.value,
                };
            }

            return null;
        },

        getOptionsResponse() {
        },

        getOptions() {
        },

        getValue() {
            // return (this.UserRequirement.value !== null) ? this.UserRequirement.value.value : null;
        },
    },

    created() {
        // For edit
        if (this.companyUserRequirement.value !== null) {
            this.companyUserRequirement.value = JSON.parse(this.companyUserRequirement.value);
            this.getHexapostById(this.companyUserRequirement.value.id);
        }
    },

    methods: {
        async searchValue(value) {
            if (value.length > 2) {
                await this.$http.get(`hexapost/search/${value}`)
                    .then((response) => {
                        this.options = response.data.data;
                    })
                    .catch((response) => {
                        const message = get(response, 'data.message', null);
                        if (message) Notifier.getInstance('App').showError(`The city "${value}" is invalid`);
                    });
            }
        },
        onSelectCity(selectedValue) {
            this.companyUserRequirement.value = {
                id: selectedValue.id,
                label: selectedValue.name,
            };
            this.updateRequirement().then(() => {
                this.$emit('onChangeValue', this.companyUserRequirement.id, true);
            });
        },
        getHelpText() {
            return this.translate('Sélectionnez "Autre" pour un pays étranger');
        },
        async getHexapostById(id) {
            await this.$http.get(`hexapost/${id}`)
                .then((response) => {
                    this.options = [response.data.data];
                    this.hexapost = response.data.data.id;
                })
                .catch((response) => {
                    const message = get(response, 'data.message', null);
                    if (message) Notifier.getInstance('App').showError(`The hexapost "${id}" is not found`);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.hexapost-help {
    color: $primary-darker;
}
.hexapost_form_selector {
    margin-bottom: 0.5rem;
}
</style>
