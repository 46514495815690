import Vue from 'vue';
import api from '@/store/api';
import PermissionPlugin from '@/plugins/PermissionPlugin';

const ADD_PERMISSIONS = 'add_permissions';
const UPDATE_PERMISSIONS = 'update_permissions';
const DELETE_PERMISSIONS = 'delete_permissions';

export default {
    namespaced: true,

    state() {
        return {
            permissions: {},
        };
    },

    getters: {
        names: (state) => Object.values(state.permissions)
            .map((permission) => permission.name),
        can: (state) => (name, options = {}, type = PermissionPlugin.TYPE_READ) => {
            const permission = state.permissions[name];

            // Cas 0 : Chargement de la permission
            if (permission === undefined) {
                return false;
            }

            // Cas 1 : La permission n'est pas encore chargée
            if (permission.loaded === false) {
                return false;
            }

            // Cas 2 : Le type n'existe pas
            if (permission.access[type] === undefined) {
                return false;
            }

            // Cas 3 : L'utilisateur a aucun droit
            if (permission.access[type].can_access === false) {
                return false;
            }

            // Cas 4 : L'utilisateur a accès globalement à la ressource
            if (permission.access[type].can_full_access === true) {
                return true;
            }

            // Cas 5 - Application des périmètres
            for (const fields of permission.access[type].perimeters.map((perimeter) => perimeter.fields)) {
                let canAccess = true;
                for (const field in fields) {
                    if (Object.prototype.hasOwnProperty.call(fields, field)) {
                        if (Number.isInteger(options[field] * 1) && typeof options[field] !== 'boolean') {
                            options[field] *= 1;
                        }

                        if (!fields[field].includes(options[field])) {
                            canAccess = false;
                            break;
                        }
                    }
                }

                if (canAccess === true) {
                    return true;
                }
            }

            return false;
        },
        useResourcePerimeter: (state) => (name) => state.permissions[name]?.access.use_resource_perimeter,
        getPermissionsLoaded: (state) => (permissions) => permissions.every((name) => state.permissions[name] && state.permissions[name].loaded === true),
    },

    actions: {
        refresh({
            state,
            dispatch,
        }, {
            names = [],
            forceCacheRefresh = false,
        }) {
            if (names.length === 0) {
                names = Object.keys(state.permissions);
            }

            const forceReload = true;

            return dispatch('load', {
                names,
                forceReload,
                forceCacheRefresh,
            });
        },
        load({
            commit,
            state,
        }, {
            names = [],
            forceReload = false,
            forceCacheRefresh = false,
        }) {
            if (!forceReload) {
                names = names.filter((p) => Object.keys(state.permissions)
                    .includes(p) === false);
            }

            if (names.length === 0) {
                return;
            }

            // Ajout des permissions
            commit(ADD_PERMISSIONS, names);

            // Chargement des permissions
            return api.permissions.getPermissions(names, forceCacheRefresh)
                .then(({ data: { permissions } }) => {
                    commit(UPDATE_PERMISSIONS, permissions);
                });
        },
        delete({ commit }, { names = [] }) {
            if (names.length === 0) {
                return;
            }

            commit(DELETE_PERMISSIONS, names);
        },
    },

    mutations: {
        [UPDATE_PERMISSIONS](state, permissions) {
            const names = Object.keys(permissions);

            for (const name of names) {
                state.permissions[name].loaded = true;
                state.permissions[name].access = permissions[name];
            }
        },
        [ADD_PERMISSIONS](state, names) {
            names.forEach((name) => {
                Vue.set(state.permissions, name, {
                    name,
                    access: {},
                    loaded: false,
                });
            });
        },
        [DELETE_PERMISSIONS](state, names) {
            names.forEach((name) => {
                Vue.set(state.permissions, name, {
                    name,
                    access: {},
                    loaded: false,
                });
            });
        },
    },
};
