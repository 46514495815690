<template>
    <div class="list-tasks">
        <ListActionsEvent
            v-if="events.length > 0"
            :events="events"
            :class="{'mb-10': tasks.length > 0}"
        />
        <ListTasksItem
            v-for="(task, index) in tasks"
            :key="`task-${task.id}-${index}`"
            :task="task"
            class="task-item"
            :select-mode="selectMode"
            @select="selectTasks"
            @update="updateTask"
            @updateStatus="updateTaskStatus"
            @delete="deleteTask"
        />
        <div
            v-if="restToLoad > 0"
            key="loading-button"
            class="task-item load-more-button"
            @click="$emit('loadMore')"
        >
            <div class="icon-wrapper">
                <FontAwesomeIcon
                    v-if="loading"
                    icon="circle-notch"
                    spin
                />
                <FontAwesomeIcon
                    v-else
                    :icon="['fa', 'plus-circle']"
                    class="plus-icon"
                />
            </div>
            <div class="button-text">
                <t>See more actions</t>
                <div class="count">
                    {{ restToLoad }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ListActionsEvent from '../../actions/ListActionsEvent.vue';
import ListTasksItem from '../item/ListTasksItem.vue';
import listTasksMixin from '../listTasksMixin';

export default {
    name: 'ListTasksByPeriod',
    components: {
        ListTasksItem,
        ListActionsEvent,
    },
    mixins: [listTasksMixin],
    props: {
        tasks: {
            type: Array,
            required: true,
            default: () => ([]),
        },
        events: {
            type: Array,
            required: true,
            default: () => ([]),
        },
        selectMode: {
            type: Boolean,
            required: false,
            default: false,
        },
        restToLoad: {
            type: Number,
            required: true,
            default: 0,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        selectTasks(payload) {
            this.$emit('select', payload);
        },
        updateTask(payload) {
            this.$emit('update', payload);
        },
        updateTaskStatus(payload) {
            this.$emit('updateStatus', payload);
        },
        deleteTask(payload) {
            this.$emit('delete', payload);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';
@import "~@/styles/transition-list";
.load-more-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: $neutral-white;
    border: 1px solid $neutral-300;
    border-radius: 16px;
    padding: 16px;
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
    cursor: pointer;

    .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        border-radius: 6px;
        background-color: $primary-background;
        color: $primary;
        height: 24px;
        width: 24px;

        .plus-icon {
            width: 10px;
            height: 10px;
        }

    }
    .button-text {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;

        .count{
            color: $primary
        }

    }
}
.task-item{
    margin-bottom: 8px;
}

</style>
