<template>
    <div>
        <StepperTabLayout v-if="isLoaded">
            <template #main>
                <HtFormCard>
                    <form class="form-2">
                        <div class="parameter-wrapper">
                            <InputField
                                :value="survey.resource.translations.name[currentLanguage.key]"
                                :label="translate('Survey name')"
                                :type="'textflag'"
                                :name="'name'"
                                :validate="isDefaultLangSelected ? 'required|max:255' : 'max:255'"
                                :validate-as="translate('name')"
                                :cypress="'survey-field-name'"
                                @languageChange="languageChange"
                                @input="updateName"
                            />

                            <HtFormSwitch
                                id="is_conversational"
                                :value="survey.is_conversational"
                                name="is_conversational"
                                :show-optional="false"
                                :label="translate('Complete in messaging')"
                                :description="translate('The person in charge of completing the questionnaire will do so via the Heyteam messaging conversational interface')"
                                :tooltip="canChangeConversationalModeTooltipMEssage"
                                :disabled="!canChangeConversationalMode"
                                @input="updateIsConversationnal"
                            />

                            <div
                                v-if="!survey.is_conversational"
                                class="supervisor-wrapper mb-5"
                            >
                                <h1>
                                    <t>Survey Background</t>
                                </h1>

                                <p class="mb-10">
                                    <t>Background image is displayed only for "non-conversational" mode</t>
                                </p>

                                <FilesUpload
                                    v-model="backgrounds"
                                    :media="['image']"
                                    :preview-only="isDisabled"
                                    is-single
                                    @on-uploaded="emitBackground"
                                    @on-remove="emitBackground"
                                />
                            </div>

                            <div class="supervisor-wrapper">
                                <div class="d-flex justify-content-between">
                                    <h1>
                                        <t>Survey supervisor</t>
                                    </h1>
                                    <FontAwesomeIcon
                                        v-if="!surveyIsNew"
                                        v-tooltip="translate('Modify them will not affect started user programs.')"
                                        :icon="['fal', 'question-circle']"
                                        class="icon-help"
                                    />
                                </div>

                                <p class="mb-4">
                                    <t>Supervisors have access to survey results and receive answers by email.</t>
                                </p>

                                <InputParticipantList
                                    :participants="survey.supervisors"
                                    @on-open="openSearchRolePanel"
                                    @on-remove="onRemoveSupervisor"
                                />
                            </div>
                        </div>
                    </form>
                </HtFormCard>
            </template>

            <template #buttonsFooter>
                <div class="parameter-footer buttons">
                    <Button
                        v-if="canDelete"
                        class="negative"
                        :state="buttonState"
                        cypress="survey-delete"
                        @click="onDelete"
                    >
                        <t>Delete</t>
                    </Button>

                    <Button
                        :state="buttonState"
                        :cypress="'survey-form-submit'"
                        @click="onSave"
                    >
                        <t>Save</t>
                    </Button>
                </div>
            </template>

            <template #modalables>
                <Modalable
                    ref="modalableSearchRolePanel"
                    class="modalable-1 small"
                    :options="{ title: translate('Supervised by')}"
                >
                    <SearchRolePanel
                        ref="searchRolePanel"
                        :multiple-mode="true"
                        is_assignable_onboardee
                        @onValidate="$refs.modalableSearchRolePanel.close()"
                    />
                </Modalable>

                <Modalable
                    ref="companySurveyRemoveModal"
                    class="modalable-1 small"
                    :mode="2"
                    :element-delete-label="translate('this survey')"
                />
            </template>
        </StepperTabLayout>
        <IconLoading v-else />
    </div>
</template>

<script>
import DefaultOrderCompletionMixin from '@/components/mixins/DefaultOrderCompletionMixin';
import showButtonActionMixin from '@/components/mixins/showButtonActionMixin';
import StepperTabLayout from '@/components/globals/StepperTabLayout.vue';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import InputField from '@/components/globals/InputField.vue';
import Button from '@/components/globals/Button.vue';
import Modalable from '@/components/globals/Modalable.vue';
import SearchRolePanel from '@/components/globals/SearchRolePanel.vue';
import { mapState } from 'vuex';
import api from '@/store/api';
import InputParticipantList from '@/components/globals/participant/InputParticipantList.vue';
import IconLoading from '@/components/icons/IconLoading.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import CompanySurveyQuestion from '@/models/CompanySurveyQuestion';
import FilesUpload from '@/components/resources/user/surveys/questions/FilesUpload.vue';
import CompanySurveyContent from '@/models/CompanySurveyContent';
import CompanyRequirementCategory from '@/models/CompanyRequirementCategory';

export default {
    name: 'SurveyFormParameters',
    components: {
        FilesUpload,
        HtFormSwitch,
        IconLoading,
        InputParticipantList,
        SearchRolePanel,
        Modalable,
        Button,
        InputField,
        HtFormCard,
        StepperTabLayout,
    },
    permissions: ['ModelCompanySurvey'],
    mixins: [
        DefaultOrderCompletionMixin,
        showButtonActionMixin,
    ],

    props: {
        showListOnEnd: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        ...mapState('stepperform', ['loading']),
        survey: {
            set(survey) {
                this.$store.dispatch('stepperform/setItem', survey);
            },
            get() {
                return this.$store.state.stepperform.item;
            },
        },

        canChangeConversationalModeTooltipMEssage() {
            return this.canChangeConversationalMode
                ? this.translate('The mode of this survey cannot be changed (requirement is present)')
                : null;
        },

        surveyIsNew() {
            return this.survey.id === undefined;
        },

        isDefaultLangSelected() {
            return this.shared.session.company.company_language.key === this.currentLanguage.key;
        },

        isDisabled() {
            return this.survey.status === 'in_progress' || this.survey.status === 'completed';
        },

        buttonState() {
            return (!this.isLoaded || this.loading) ? 'loading' : 'idle';
        },

        canDelete() {
            return this.showButtonDelete === true && !this.surveyIsNew;
        },

        isLoaded() {
            return this.$store.state.roles.rolesLoaded;
        },

        doesContainRequirement() {
            if (!this.survey || !this.survey.contents) {
                return false;
            }

            return this.survey.contents
                .some((content) => content.resource === CompanySurveyContent.RESOURCE_QUESTION
                    && content.question.resourceable_type === CompanyRequirementCategory.RESOURCEABLE_CLASS);
        },

        canChangeConversationalMode() {
            return !this.doesContainRequirement;
        },
    },

    created() {
        this.$store.dispatch('roles/fetchRoles')
            .then(() => {
                if (this.surveyIsNew && this.survey.supervisors.length === 0) {
                    const managerRole = this.$store.state.roles.roles.find((role) => role.name === 'manager');

                    this.survey = {
                        ...this.survey,
                        supervisors: [
                            ...this.survey.supervisors, {
                                user: null,
                                company_user_id: null,
                                company_role_id: managerRole.id,
                                role: managerRole,
                            },
                        ],
                    };
                }
            });

        if (this.survey.company_file) {
            this.backgrounds = [{ ...this.survey.company_file }];
        }
    },

    methods: {
        updateName(newName) {
            const newSurvey = { ...this.survey };
            newSurvey.resource.translations.name[this.currentLanguage.key] = newName;
            this.survey = newSurvey;
        },

        updateIsConversationnal(newIsConversationnal) {
            const newSurvey = { ...this.survey };
            newSurvey.is_conversational = newIsConversationnal;

            if (!newIsConversationnal) {
                newSurvey.contents = this.survey.contents
                    ? this.survey.contents.filter(
                        (content) => content.resource === CompanySurveyContent.RESOURCE_EXTRA || (content.resource === CompanySurveyContent.RESOURCE_QUESTION && CompanySurveyQuestion.NON_CONVERSATIONAL_TYPES.includes(content.question.type)),
                    )
                    : [];
            }
            this.survey = newSurvey;
        },

        languageChange(val) {
            this.currentLanguage = val;
        },

        openSearchRolePanel() {
            this.$refs.searchRolePanel.init({
                onValidate: this.addSupervisor,
                selected: this.survey.supervisors,
            });

            this.$refs.modalableSearchRolePanel.open();
        },

        addSupervisor(supervisors) {
            this.survey = {
                ...this.survey,
                supervisors: supervisors.map((supervisor) => ({
                    user: supervisor.company_user || null,
                    company_user_id: supervisor.company_user_id || null,
                    role: supervisor.company_role || null,
                    company_role_id: supervisor.company_role_id || null,
                })),
            };
        },

        onRemoveSupervisor(indexToRemove) {
            this.survey = {
                ...this.survey,
                supervisors: this.survey.supervisors.filter((supervisor, index) => index !== indexToRemove),
            };
        },

        onDelete() {
            this.$refs.companySurveyRemoveModal.deleteWindow({ content: this.translate('Are you sure you want to delete this survey? Once you click on delete, you will no longer be able to access this survey.') })
                .then((result) => {
                    if (result) {
                        api.configuration.surveys
                            .delete(this.survey.id)
                            .then(() => {
                                this.$emit('onDelete');
                            });
                    }
                });
        },

        afterSave(res) {
            this.$store.dispatch('stepperform/setItem', res.data.data);
            this.$store.dispatch('stepperform/disableLoading');

            this.$emit('goTo', 'targets');
        },

        onSave() {
            this.$store.dispatch('stepperform/enableLoading');

            this.currentLanguage = this.shared.session.company.company_language;

            this.$nextTick(() => {
                this.$validator.validateAll().then((isSuccess) => {
                    if (isSuccess) {
                        if (this.surveyIsNew) {
                            api.configuration.surveys
                                .create(this.survey)
                                .then(this.afterSave);
                        } else {
                            api.configuration.surveys
                                .update(this.survey.id, this.survey)
                                .then(this.afterSave);
                        }
                    } else {
                        this.$store.dispatch('stepperform/disableLoading');
                    }
                });
            });
        },

        emitBackground(file) {
            if (this.isDisabled) {
                return;
            }

            this.survey.company_file_id = file ? file.id : null;
        },
    },

    data() {
        return {
            currentLanguage: this.shared.session.company.company_language,
            backgrounds: [],
        };
    },
};
</script>

<style lang="scss" scoped src="./SurveyFormParameters.scss"></style>
