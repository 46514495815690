<template>
    <div class="ht-table-wrapper">
        <div
            v-if="filterComponent"
            class="ht-table-header"
        >
            <Component
                :is="filterComponent"
                v-model="selectedEntities"
                :program-entities-ids="programEntitiesIds"
                is-single
                @input="debounceLoadItems"
            >
                <template #topFilter>
                    <HtSearchField
                        id="search"
                        v-model="search"
                        name="search"
                        class="search"
                        @input="debounceLoadItems"
                    />
                </template>
                <template #title>
                    <div>
                        <h1 class="title">
                            {{ title }}
                        </h1>
                        <div
                            v-if="subtitle"
                            class="subtitle"
                        >
                            {{ subtitle }}
                        </div>
                    </div>
                </template>
                <template
                    v-if="showButton"
                    #button
                >
                    <HtButton
                        cypress="button-action-add"
                        @click.native="$emit('on-button-clicked')"
                    >
                        <span class="hide-mobile">
                            {{ buttonText }}
                        </span>
                        <span class="hide-desktop">
                            <FontAwesomeIcon
                                :icon="['fal', 'plus']"
                                class="plus"
                            />
                        </span>
                    </HtButton>
                </template>
            </Component>
        </div>
        <div class="ht-table-paginated">
            <div class="table-summary">
                <div class="table-summary-results">
                    <Skeleton
                        v-if="loading"
                        width="120px"
                        :loading="true"
                    />
                    <t
                        v-else-if="lastPage === 1"
                        :count="items.length"
                    >
                        {count} result | {count} results
                    </t>
                    <t
                        v-else
                        :count="items.length"
                        :total="total"
                    >
                        {count} / {total} result | {count} / {total} results
                    </t>
                </div>
                <div
                    v-if="lastPage > 1"
                    class="table-summary-pages"
                >
                    <t
                        :count="page"
                        :total="lastPage"
                    >
                        Page {count} out of {total}
                    </t>
                </div>
            </div>

            <HtTableExtended
                :items="items"
                :columns="columns"
                :cypress="cypress"
                :has-quick-actions="false"
                :loading="loading"
                :skeleton-rows="perPage"
                :has-checkboxes="hasCheckboxes"
                :cell-errors="cellErrors"
                @sort-column="sortColumn"
                @on-row-clicked="$emit('on-row-clicked', $event)"
                @on-action-clicked="$emit('on-action-clicked', $event)"
                @on-toggle-checkbox="onToggleCheckbox"
                @on-toggle-all-checkbox="onCheckAll"
            >
                <template
                    v-for="(_, slot) of $scopedSlots"
                    #[slot]="scope"
                >
                    <slot
                        :name="slot"
                        v-bind="scope"
                    />
                </template>
            </HtTableExtended>
        </div>
        <HtPagination
            v-model="page"
            :last-page="lastPage"
        />
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Skeleton } from 'vue-loading-skeleton';
import CypressMixin from '@/mixins/CypressMixin';
import ProgramFilters from '@/components/pages/program/filters/ProgramFilters.vue';
import ResourceBarFilters from '@/components/resources/ResourceBarFilters.vue';
import HtEntitiesFiltersWrapper from '@/components/globals/filters/HtEntitiesFiltersWrapper.vue';
import HtPagination from '../HtPagination.vue';
import HtTableExtended from './HtTableExtended.vue';
import HtButton from '../HtButton.vue';
import HtSearchField from '../HtSearchField.vue';

export default {
    name: 'HtTablePaginated',
    components: {
        HtPagination,
        HtTableExtended,
        Skeleton,
        ProgramFilters,
        HtButton,
        HtSearchField,
        FontAwesomeIcon,
        ResourceBarFilters,
        HtEntitiesFiltersWrapper,
    },
    mixins: [
        CypressMixin,
    ],
    props: {
        columns: {
            type: Array,
            default: () => [],
        },
        url: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        buttonText: {
            type: String,
            default: null,
        },
        showButton: {
            type: Boolean,
            default: false,
        },
        programEntitiesIds: {
            type: Array,
            default: () => ([]),
        },
        type: {
            type: String,
            default: () => null,
            validator: (value) => [
                'program',
                // 'resource',
            ].includes(value),
        },
        perPage: {
            type: Number,
            default: 15,
        },
        externalSearch: {
            type: String,
            default: () => null,
        },
        hasCheckboxes: {
            type: Boolean,
            default: false,
        },
        cellErrors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            items: [],
            loading: false,
            total: 0,
            lastPage: 1,
            page: 1,
            sortKey: undefined,
            sortDirection: undefined,
            selectedEntities: [],
            search: '',
            showFilters: false,
        };
    },
    computed: {
        cellErrorsId() {
            return Object.keys(this.cellErrors);
        },
        paginate() {
            return this.total > this.items.length;
        },
        filterComponent() {
            switch (this.type) {
            case 'program':
                return 'HtEntitiesFiltersWrapper';
            // case 'resource':
            //     return 'ResourceBarFilters';
            default:
                return null;
            }
        },
        selectedItems() {
            if (!this.hasCheckboxes) {
                return false;
            }

            return this.items.filter((item) => item.is_checked);
        },
        hasSelectedItems() {
            if (!this.hasCheckboxes) {
                return false;
            }

            return this.items.some((item) => item.is_checked);
        },
    },
    watch: {
        page() {
            this.debounceLoadItems();
        },
        externalSearch() {
            if (!this.filterComponent) {
                this.search = this.externalSearch;
                this.debounceLoadItems();
            }
        },
        hasSelectedItems() {
            this.$emit('has-selected-items', this.hasSelectedItems);
        },
    },
    created() {
        this.loadItems();
    },
    methods: {
        onToggleCheckbox(toggleItem) {
            this.items = this.items.map((item) => {
                if (item.id === toggleItem.id) {
                    return { ...item, is_checked: !toggleItem.is_checked };
                }

                return item;
            });
        },
        onCheckAll(isAllChecked) {
            this.items = this.items.map((item) => ({ ...item, is_checked: isAllChecked }));
        },
        sortColumn({ column, direction }) {
            this.sortKey = column;
            this.sortDirection = direction;
            this.debounceLoadItems();
        },
        replaceItemById(item, key, newItem) {
            const currentItemIndex = this.items.indexOf(item);
            if (currentItemIndex !== -1) {
                this.items[currentItemIndex][key] = newItem;
            }
        },
        loadItems(page = null) {
            this.loading = true;
            this.$http.post(
                this.url,
                {
                    per_page: this.perPage,
                    page: page || this.page,
                    filters: {
                        entities: this.selectedEntities,
                        search: this.search,
                        ids: this.cellErrorsId,
                    },
                    sort_key: this.sortKey,
                    sort_direction: this.sortDirection,
                },
            ).then((response) => {
                this.items = response.data.data;

                if (this.hasCheckboxes) {
                    this.items = this.items.map((item) => {
                        item.is_checked = false;

                        return item;
                    });
                }

                if (response.data.meta) {
                    this.total = response.data.meta.total;
                    this.lastPage = response.data.meta.last_page;
                } else {
                    this.total = response.data.total;
                    this.lastPage = response.data.last_page;
                }
                this.$emit('pagination-refreshed', { total: this.items.length });
            }).finally(() => {
                this.loading = false;
            });
        },
        debounceLoadItems: debounce(function () { this.loadItems(); }, 300),
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.ht-table-wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;

    .ht-table-header{
        .title {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 4px;
            align-self: flex-start;
        }
        .subtitle {
            font-size: 14px;
            color: $neutral-500;
        }
    }
    .ht-table-paginated {
        background-color: $neutral-white;
        font-size: 14px;
        color: $neutral-800;
        border: 1px solid $neutral-300;
        border-radius: 8px;

        .table-summary {
            font-weight: 600;
            color: $neutral-black;
            padding: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .table-summary-pages {
                color: $neutral-500;
            }
        }
    }
}

@media (max-width: $tablet) {
    .hide-mobile {
        display: none;
    }
}
@media (min-width: $tablet) {
    .hide-desktop {
        display: none;
    }
}
</style>
