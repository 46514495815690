<template>
    <div
        v-if="isLoaded"
        class="form-2"
    >
        <FocBlock title="Personal information">
            <!-- GENDER -->
            <GenderSelection
                :company-user="user"
                :disabled="!canEditUserData"
            />

            <div class="row">
                <!-- FIRSTNAME -->
                <HtFormInput
                    :id="'firstname'"
                    v-model="user.firstname"
                    v-validate.disable="'required|max:64'"
                    :cypress="'firstname'"
                    class="col-12"
                    :name="'firstname'"
                    :placeholder="translate('Firstname')"
                    :label="translate('Firstname')"
                    :is-required="true"
                    :show-optional="false"
                    :show-asterisk="true"
                    :max-length="64"
                    :data-vv-as="translate('firstname')"
                    :disabled="!canEditUserData"
                />
            </div>

            <div class="row">
                <!-- LASTNAME -->
                <HtFormInput
                    :id="'lastname'"
                    v-model="user.lastname"
                    v-validate.disable="'required|max:64'"
                    :cypress="'lastname'"
                    class="col-12"
                    :name="'lastname'"
                    :placeholder="translate('Lastname')"
                    :label="translate('Lastname')"
                    :is-required="true"
                    :show-optional="false"
                    :show-asterisk="true"
                    :max-length="64"
                    :data-vv-as="translate('lastname')"
                    :disabled="!canEditUserData"
                />
            </div>

            <div class="row">
                <!-- EMAIL PRO -->
                <div class="ht-form col-12">
                    <HtFormInput
                        :id="'professional-email'"
                        v-model="user.professional_email"
                        v-validate.disable="(isProgramWithProfessionalEmailRequired && !isProfessionalEmailDisabled ? 'required|' : '')
                            + 'email'"
                        :cypress="'professional-email'"
                        :name="'professional-email'"
                        class="mb-2"
                        :placeholder="translate('Professional e-mail address')"
                        :label="translate('Professional e-mail address')"
                        :is-required="isProgramWithProfessionalEmailRequired && !isProfessionalEmailDisabled"
                        :show-asterisk="isProgramWithProfessionalEmailRequired && !isProfessionalEmailDisabled"
                        :max-length="255"
                        :tooltip="tooltipProfessionalEmail"
                        :data-vv-as="translate('professional email')"
                        :disabled="!canEditUserData"
                        @focus="autoFillEmail"
                    />

                    <HtFormSwitch
                        v-if="!isProgramWithPersonalEmailRequired && !user.professional_email"
                        :id="'disable-professional-email'"
                        v-model="isProfessionalEmailDisabled"
                        :label="translate('Professional email is not used for this employee')"
                        :description="translate('The email used for communication will be the personal email')"
                        :name="'disable-professional-email'"
                    />
                </div>
            </div>

            <div class="row">
                <!-- EMAIL PERSO -->
                <HtFormInput
                    :id="'personnal-email'"
                    v-model="user.personal_email"
                    v-validate.disable="(isProgramWithPersonalEmailRequired || isProfessionalEmailDisabled ? 'required|' : '')
                        + 'email'"
                    :cypress="'personnal-email'"
                    class="ht-form col-12"
                    :name="'personnal-email'"
                    :placeholder="translate('Personnal e-mail address')"
                    :label="translate('Personnal e-mail address')"
                    :is-required="isProgramWithPersonalEmailRequired || isProfessionalEmailDisabled"
                    :show-asterisk="isProgramWithPersonalEmailRequired || isProfessionalEmailDisabled"
                    :max-length="255"
                    :tooltip="tooltipPersonalEmail"
                    :data-vv-as="translate('personnal email')"
                    :disabled="!canEditUserData"
                />
            </div>

            <div class="row">
                <!-- LANGUAGE -->
                <HtLanguageSelect
                    id="language"
                    :cypress="'language'"
                    name="language"
                    :value="shared.session.company.company_languages.enabled
                        .find(language => language.id === user.company_language_id)"
                    :custom-option-label="customLanguageOptionLabel"
                    class="col-12"
                    :disabled="!canEditUserData"
                    @input="userLanguageChanged"
                />
            </div>

            <div class="row">
                <HtTimezoneSelect
                    v-model="user.timezone"
                    :cypress="'timezone'"
                    class="timezone col-12"
                    :disabled="!canEditUserData"
                />
            </div>

            <Checkable
                v-if="company_is_two_factor_auth_enabled"
                v-model="user.is_two_factor_auth_enabled"
                :label="translate('Two factor Authentication')"
                :cypress="'renew-probation-period-check'"
            />

            <phone-number-input
                :value="user"
                :label="translate('Phone number')"
                :is-required="user.is_two_factor_auth_enabled"
                @input="updatePhoneNumber"
            />
        </FocBlock>
        <FocBlock title="Filter">
            <!-- FILTERS -->
            <HtFormMultiselectFilter
                v-model="choosen_entities"
                :entities-linked-to-program-ids="entitiesLinkedToProgramIds"
                :disabled="user_program.has_default_resources"
                :show-optional="false"
                :show-asterisk="true"
            />
        </FocBlock>
        <FocBlock title="Program parameters">
            <!-- PROGRAM CREATION DATE -->
            <HtFormFlatPickr
                :id="'main-key-date'"
                v-model="mainKeyDate.starts_at"
                v-validate.disable="'required'"
                :name="'main-key-date'"
                :label="mainKeyDate.name"
                :placeholder="mainKeyDate.name"
                :show-optional="false"
                :show-asterisk="true"
                :data-vv-as="mainKeyDate.name"
                :is-working-day="false"
                :static="false"
                :cypress="'main-key-date'"
            />

            <!-- PROGRAM END DATE -->
            <HtFormFlatPickr
                v-if="companyContractSelected && companyContractSelected.has_end_date"
                :id="'program-end-date'"
                v-model="user.end_date"
                v-validate.disable="'required'"
                :name="'program-end-date'"
                :label="translate('Program end date')"
                :placeholder="translate('Program end date')"
                :value="user.end_date"
                :show-optional="false"
                :show-asterisk="true"
                :data-vv-as="translate('program end date')"
                :is-working-day="false"
                :static="false"
                :cypress="'program-end-date'"
            />

            <!-- PROBATION PERIOD WITH RENEWED -->
            <div v-if="companyContractSelected && companyContractSelected.has_probation">
                <ProbationPeriod
                    :label="'Initial probationary period'"
                    :probation.sync="user.first_probation"
                    :probation_unit.sync="user.first_probation_unit"
                    :cypress="'first-probation-unit'"
                />
                <Checkable
                    v-model="has_renewed_probation"
                    :label="translate('Renewed probationary period')"
                    :cypress="'renew-probation-period-check'"
                />
                <ProbationPeriod
                    v-if="has_renewed_probation"
                    :label="'Renewed probationary period'"
                    :probation.sync="user.second_probation"
                    :probation_unit.sync="user.second_probation_unit"
                    :cypress="'second-probation-unit'"
                />
            </div>

            <HtFormFlatPickr
                v-for="(keyDate, index) in secondaryKeyDates"
                :id="`${keyDate.id}`"
                :key="keyDate.id"
                v-model="keyDate.starts_at"
                v-validate.disable="'required'"
                :name="`secondary-key-date${index}`"
                :label="keyDate.name"
                :placeholder="keyDate.name"
                :show-optional="false"
                :show-asterisk="true"
                :data-vv-as="keyDate.name"
                :is-working-day="false"
                :static="false"
                :cypress="`${'secondaryKeyDate' - index}`"
            />
            <HtToast
                :title="translate('These information are editable')"
                :message="toastMessage"
                :closable="false"
                type="info"
            />
        </FocBlock>
        <EnrollingFooter>
            <Button
                cypress="foc-next-new"
                :state="buttonState"
                @click="validate()"
            >
                <t>Next</t>
            </Button>
        </EnrollingFooter>
    </div>
</template>

<script>

import GenderSelection from '@/components/globals/GenderSelection.vue';
import HtLanguageSelect from '@/components/globals/HtLanguageSelect.vue';
import PhoneNumberInput from '@/components/globals/inputs/PhoneNumberInput.vue';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtToast from '@/components/globals/HtToast.vue';
import HtFormMultiselectFilter from '@/components/globals/HtFormMultiselectFilter.vue';
import HtFormFlatPickr from '@/components/globals/HtFormFlatPickr.vue';
import ProbationPeriod from '@/components/ProbationPeriod.vue';
import HtTimezoneSelect from '@/components/globals/HtTimezoneSelect.vue';
import FocBlock from '@/components/pages/foc/FocBlock.vue';
import HtProgramType from '@/models/HtProgramType';
import GoToDraftUserModalMixin from '@/router/pages/programs/modals/GoToDraftUserModalMixin';
import I18n from '@/modules/i18n/I18n';
import Utils from '@/Utils';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import api from '@/store/api';
import EnrollingFooter from './EnrollingFooter.vue';

export default {
    name: 'NewEnrollingNew',
    components: {
        HtFormSwitch,
        HtTimezoneSelect,
        HtFormFlatPickr,
        HtFormMultiselectFilter,
        GenderSelection,
        HtLanguageSelect,
        HtFormInput,
        HtToast,
        PhoneNumberInput,
        ProbationPeriod,
        EnrollingFooter,
        FocBlock,
    },
    mixins: [GoToDraftUserModalMixin],
    props: {
        programId: {
            type: Number,
            default: null,
        },
        userId: {
            type: Number,
            default: null,
        },
        userProgramId: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            user_id: this.userId,
            isLoaded: false,
            buttonState: 'idle',
            choosen_entities: {},
            has_renewed_probation: false,
            program_type: null,
            user_program: {
                has_default_resources: false,
            },
            user: {
                id: null,
                gender: 'neutral',
                firstname: null,
                lastname: null,
                professional_email: null,
                personal_email: null,
                company_language_id: this.shared.session.company.company_language.id,
                timezone: 'Europe/Paris',
                phone_country_code: null,
                phone_number: null,
                end_date: null,
                first_probation: null,
                first_probation_unit: null,
                second_probation: null,
                second_probation_unit: null,
                is_two_factor_auth_enabled: false,
            },
            toastMessage: I18n.translate('You can change the dates entered in the user\'s profile once the program has been created'),
            mainKeyDate: null,
            secondaryKeyDates: [],
            entitiesLinkedToProgramIds: [],
            isProfessionalEmailDisabled: false,
            company_is_two_factor_auth_enabled: false,
        };
    },

    computed: {
        isOnboarding() {
            return HtProgramType.SLUG_ONBOARDING === this.program_type;
        },

        isPreboarding() {
            return HtProgramType.SLUG_PREBOARDING === this.program_type;
        },

        companyContractSelected() {
            return (this.isOnboarding && this.choosen_entities.company_contract);
        },

        isProgramWithPersonalEmailRequired() {
            return this.isOnboarding
                || this.isPreboarding;
        },

        isProgramWithProfessionalEmailRequired() {
            return !this.isProgramWithPersonalEmailRequired;
        },

        tooltipProfessionalEmail() {
            return this.isOnboarding
                ? this.translate('This e-mail address will be used as login and communication way from the date you have chosen in the program.')
                : null;
        },

        tooltipPersonalEmail() {
            return this.isOnboarding
                ? this.translate('This e-mail address will be used as login and communication way as long as the professional one hasn\'t been set and activated.')
                : null;
        },
        entitiesLinkedToProgram() {
            return this.$store.getters['entities/getEntitiesFromIds'](this.entitiesLinkedToProgramIds);
        },

        isUserExisting() {
            return !!this.userId;
        },

        canEditUserData() {
            return !this.isUserExisting || this.$can('AbstractProfilePersonal', { company_user_id: this.userId });
        },
    },

    watch: {
        'user.professional_email': function (value) {
            if (value.trim !== '') {
                this.isProfessionalEmailDisabled = false;
            }
        },
    },

    created() {
        this.$store.dispatch('programs/fetchPrograms');
        this.$store.dispatch('entities/fetchEntities').then(() => {
            this.loadEnrolleeInfo(this.user_id, this.programId, this.userProgramId);
        });
    },

    methods: {
        loadEnrolleeInfo(userId, programId, userProgramId) {
            return new Promise((resolve) => {
                // TODO: externaliser les call http des composants
                this.$http.post('foc/enrolling/information', {
                    program_id: programId,
                    user_id: userId,
                    user_program_id: userProgramId,
                }).then((response) => {
                    this.company_is_two_factor_auth_enabled = response.data.company_is_two_factor_auth_enabled;
                    this.entitiesLinkedToProgramIds = response.data.entitiesLinkedToProgramIds;
                    this.program_type = response.data.program_type;
                    this.mainKeyDate = {
                        ...this.mainKeyDate,
                        ...response.data.main_key_date,
                    };
                    this.secondaryKeyDates = response.data.secondary_key_dates;

                    if (typeof response.data.user === 'undefined' || !response.data.user) {
                        this.user.id = userId || null;

                        this.user.phone_country_code = '+33';

                        this.$set(this.user, 'is_two_factor_auth_enabled', this.company_is_two_factor_auth_enabled || false);

                        this.fillForDevelopment();
                    } else {
                        this.user.id = response.data.user.id;

                        if (response.data.program) {
                            this.user_program = response.data.program;
                        }
                        Object.keys(this.user).forEach((index) => {
                            if (typeof this.user[index] !== 'undefined') {
                                this.user[index] = response.data.user[index];
                                // Setter les entitées directement depuis le user
                                // si inexistante dans la table company_user_entity
                                if (!response.data.defaultEntities && ['company_office_id', 'company_contract_id', 'company_department_id', 'company_job_position_id']
                                    .includes(index)) {
                                    const entity = this.entitiesLinkedToProgram
                                        .filter((e) => e.slug === index.slice(0, -3))[0];
                                    const value = this.$store.getters['entities/valuesByEntityId'][entity.id]
                                        .filter((v) => v.id === response.data.user[index])[0];
                                    this.$set(this.choosen_entities, entity.slug, value);
                                }
                            }
                        });
                        // Setter les entitées si existante dans la table company_user_entity
                        if (response.data.defaultEntities) {
                            this.entitiesLinkedToProgram
                                .forEach((entity) => {
                                    response
                                        .data
                                        .defaultEntities
                                        .forEach((defaultEntity) => this.$store.getters['entities/valuesByEntityId'][entity.id]
                                            .forEach((value) => {
                                                if (value.company_entity_id
                                                    === defaultEntity.company_entity_id
                                                    && value.company_entity_value_id
                                                        === defaultEntity.company_entity_value_id
                                                ) {
                                                    this.$set(
                                                        this.choosen_entities,
                                                        entity.slug,
                                                        value,
                                                    );
                                                }
                                            }));
                                });
                        }
                        this.has_renewed_probation = !!response.data.user.second_probation;
                    }
                }).finally(() => {
                    this.isLoaded = true;
                    resolve();
                });
            });
        },
        getEmailToCheckForExistingPrograms() {
            const professionalEmailError = this.errors.collect('professional-email', null, false)?.[0];
            const personnalEmailError = this.errors.collect('personnal-email', null, false)?.[0];

            if (personnalEmailError?.rule === 'unique_email' && professionalEmailError?.rule === 'unique_email') {
                if (this.fields['professional-email'].required) {
                    return this.user.professional_email;
                }
                return this.user.personal_email;
            }

            if (personnalEmailError?.rule === 'unique_email') {
                return this.user.personal_email;
            }

            if (professionalEmailError?.rule === 'unique_email') {
                return this.user.professional_email;
            }

            return this.fields['professional-email'].required ? this.user.professional_email : this.user.personal_email;
        },

        async handleExisting() {
            const mailToSearch = this.getEmailToCheckForExistingPrograms();
            if (!mailToSearch) return Promise.resolve();
            const user = await this.userAlreadyExist(mailToSearch);
            if (!user) return Promise.resolve();
            const programId = this.programId || this.userProgram.company_program_id;

            if (!user.company_user_program.length || (!this.userProgramId && !this.user_id)) {
                return this.replaceUrlInfo(user, {
                    program_id: programId,
                    user_id: user.id,
                });
            }

            try {
                const enrollingCheck = await api.user.enrolling.checker
                    .check(user.id, parseInt(this.programId, 10), this.userProgramId);

                const { reason, program } = enrollingCheck.data.data;

                if (reason === 'USER_HAS_ACTIVE_PROGRAM') {
                    return this.showActiveModal(program, programId);
                } if (reason === 'USER_HAS_DRAFT_PROGRAM') {
                    return this.showDraftModal(program, programId);
                } if (reason === 'USER_MISMATCH_PROGRAM_LAUNCH_CONDITION') {
                    this.showMismatchProgramModal();
                    return Promise.reject();
                } if (reason === 'USER_CAN_LAUNCH_PROGRAM') {
                    return Promise.resolve();
                }
            } catch (error) {
                this.$Notifier('App').showError(this.translate('Error checking user program launch conditions'));
                return Promise.reject();
            }
            return Promise.reject();
        },

        replaceUrlInfo(userId, query) {
            this.errors.clear();
            this.user.id = userId;
            this.user_id = userId;
            this.$router.replace({
                query,
            });
            return this.loadEnrolleeInfo(query.user_id, query.program_id, query.user_program_id);
        },

        userAlreadyExist(criteria) {
            const params = {
                search: criteria,
                limit: 1,
                withPrograms: true,
            };
            // TODO: externaliser les call http des composants
            return this.$http.post('company_user_search', params).then(({ data }) => (data.search.length === 1 ? data.search[0] : null));
        },

        getMatchedProgram(userPrograms, programId, status) {
            const sameUserCriteria = (id) => (this.userProgramId ? this.userProgramId !== id : true);
            return userPrograms.find(
                (program) => program.status === status && program.company_program_id === programId
                    && sameUserCriteria(program.id),
            );
        },

        showDraftModal(draftProgram, programId) {
            return this.goToDraftUserModal(draftProgram).then((choice) => {
                if (choice === 'create') {
                    return this.replaceUrlInfo(draftProgram.company_user_id, {
                        program_id: programId,
                        user_id: draftProgram.company_user_id,
                    });
                } if (choice === 'existing') {
                    return this.replaceUrlInfo(draftProgram.company_user_id, {
                        user_program_id: draftProgram.id,
                    });
                }
                return Promise.reject();
            });
        },

        showActiveModal(activeProgram, programId) {
            return new Promise((resolve, reject) => {
                this.genericConfirm(
                    this.translate('This user already have an active program'),
                    this.translate('Do you want to close the active program ?'),
                    null,
                    this.translate('Close active program'),
                    this.translate('See active program'),
                ).then(() => {
                    // TODO: externaliser les call http des composants
                    this.$http.post('dashboard/statistics/close-programs', {
                        programs: [activeProgram.id],
                    }).then(() => {
                        this.$Notifier('App').showInfo(this.translate('Active program closed'));
                        this.replaceUrlInfo(activeProgram.company_user_id, { program_id: programId, user_id: activeProgram.company_user_id }).then(() => {
                            resolve();
                        });
                    }).catch(() => {
                        this.$Notifier('App').showError(this.translate('An error occured while closing the active program'));
                        reject();
                    });
                }).catch((error) => {
                    if (error === 'decline') {
                        this.$router.push({ path: `/Profile/${activeProgram.company_user_id}/Program/${activeProgram.id}` });
                    }
                    reject();
                });
            });
        },

        showMismatchProgramModal() {
            this.openDialogModal(
                'goToMismatchProgramModal',
                this.translate('Alert'),
                this.translate('Please check your entries, as this user cannot be invited to the program: they do not meet the program invitation criteria.'),
                [
                    {
                        title: this.translate('Ok'),
                        type: 'primary',
                        handler: () => {
                            this.$modal.hide('goToMismatchProgramModal');
                        },
                    },
                ],
            );
        },

        // 1
        async validate() {
            this.buttonState = 'loading';
            const validatorResult = await this.$validator.validateAll();
            if (!validatorResult) {
                this.$Utils.scrollToFirstError(this);
                this.buttonState = 'idle';
            } else {
                this.handleExisting().then(() => {
                    const saveData = {
                        program_id: this.programId,
                        user_program_id: this.userProgramId,
                        user_data: this.user,
                        program_entities: this.choosen_entities,
                        key_dates: [...this.secondaryKeyDates, this.mainKeyDate],
                    };
                    // TODO: externaliser les call http des composants
                    this.$http.post('foc/enrolling/information/save', saveData).then(({ data }) => {
                        this.$emit('on-next', data.user_program_id);
                        this.buttonState = 'idle';
                    });
                }).catch(() => {
                    this.buttonState = 'idle';
                });
            }
        },
        customLanguageOptionLabel(language) {
            return language.name;
        },
        userLanguageChanged(language) {
            this.user.company_language_id = language.id;
        },
        fillForDevelopment() {
            if (this.$env.isDev()) {
                const uniq = this.$Utils.moment().valueOf();

                this.user.gender = 'male';
                this.user.firstname = `test${uniq}`;
                this.user.lastname = `test${uniq}`;
                this.user.personal_email = `test@${uniq}.com`;
                this.user.professional_email = `test@${uniq}.com`;

                for (let i = 0; i < this.entitiesLinkedToProgram.length; i += 1) {
                    this.$set(
                        this.choosen_entities,
                        this.entitiesLinkedToProgram[i].slug,
                        this.$store.getters['entities/valuesByEntityId'][this.entitiesLinkedToProgram[i].id][0],
                    );
                }

                this.mainKeyDate.starts_at = Utils.moment().format('YYYY-MM-DD');

                this.secondaryKeyDates.forEach((keyDate, index) => {
                    this.$set(
                        keyDate,
                        'starts_at',
                        Utils.moment().add(index + 1, 'week').format('YYYY-MM-DD'),
                    );
                });
            }
        },

        autoFillEmail() {
            if (!this.user.professional_email
                && this.user.firstname.length > 0
                && this.user.lastname.length > 0
            ) {
                const email = `${this.user.firstname.toLowerCase()}.${this.user.lastname.toLowerCase()}`;

                this.user.professional_email = `${this.$Utils.removeDiacritics(email).replace(/([^a-zA-Z0-9._%+-])/g, '')}@`;
            }
        },

        updatePhoneNumber(value) {
            this.user.phone_country_code = value.phone_country_code;
            this.user.phone_number = value.phone_number;
        },
    },
};
</script>
