<template>
    <div
        v-if="open"
        class="first-visit"
    >
        <div class="overlay" />
        <div class="first-visit-popup">
            <button
                class="close-btn"

                @click="close"
            >
                <HtIcon
                    size="24"
                    name="xmark"
                />
            </button>
            <div class="header-popup">
                <div class="header-images">
                    <HtAvatar
                        :user="$Utils.getAvatarData(companyUser)"
                        size="xl"
                    />
                    <div class="company-logo">
                        <img :src="companyLogo">
                    </div>
                </div>
                <div class="header-text">
                    <div class="header-title">
                        {{ translate('Welcome to {companyName} {userFirstName}', {
                            companyName: company.name,
                            userFirstName: companyUser.firstname
                        }) }}
                    </div>
                    <div class="header-description">
                        <t>To make your arrival as easy as possible, we've designed an onboarding program just for you. This program is organized around 4 distinct phases, as follows</t>
                    </div>
                </div>
            </div>
            <div class="wrapper-welcome-steps">
                <div class="welcome-steps">
                    <div
                        v-for="step in steps"
                        :key="step.name"
                        class="welcome-step"
                    >
                        <HtEmojiIcon
                            size="50"
                            font-size="20"
                            theme="primary"
                        >
                            {{ step.icon }}
                        </HtEmojiIcon>
                        <div class="step-name">
                            <t>{{ step.name }}</t>
                        </div>
                        <div class="step-progress">
                            <div
                                class="current-progress"
                                :style="{'--progress': step.progress}"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <HtButton
                size="large"
                class="start-btn"
                @click="close"
            >
                {{ translate('Start my program') }}
            </HtButton>
        </div>
    </div>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import HtButton from '@/components/miscellaneous/HtButton.vue';

export default {
    name: 'FirstVisitPopup',
    components: {
        HtButton,
    },

    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },
    data() {
        return {
            open: true,
            steps: [
                {
                    icon: '👋',
                    name: 'Before first day',
                    progress: 0.5,
                },
                {
                    icon: '🎉',
                    name: 'First day',
                    progress: 0,
                },
                {
                    icon: '💪',
                    name: 'First week',
                    progress: 0,
                },
                {
                    icon: '💼',
                    name: '3 first months',
                    progress: 0,
                },
            ],
        };
    },
    computed: {
        companyLogo() {
            return this.$Utils.resolveS3PublicImage(this.shared.session.company.image_square);
        },
        companyUser() {
            return this.shared.session.companyUser;
        },
        company() {
            return this.shared.session.company;
        },
    },
    methods: {
        close() {
            this.$emit('close');
            this.open = false;
            // Clear query from url
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    from: null,
                },
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';
@import '~@/styles/ds/typography';

.first-visit {
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.overlay {
    position: absolute;
    inset: 0;
    background-color: color-mix(in srgb, var(--branding-color) 10%, #000);
    opacity: 0.5;
    z-index: -1;
}
.first-visit-popup {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    height: 100%;
    width: 100%;
    padding: 100px 20px 40px;
    background: linear-gradient(180deg, #E7F3F3 0%, #FFF 39.99%);
}

.close-btn {
    display: none;
}

.header-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
}

.company-logo {
    width: 120px;
    height: 120px;
    padding: 20px;
    margin-left: -28px;
    border-radius: 50%;
    border: 2px solid var(--fill-secondary);
    overflow: hidden;
    object-fit: contain;
    background-color: var(--fill-primary);
    img {
        height: 100%;
        width: 100%;
    }
}

.header-images {
    display: flex;
    margin-bottom: 20px;
    div.ht-avatar {
        width: 120px;
        height: 120px;
    }
}

.header-text {
    margin-bottom: 32px;
}
.header-title {
    @include ht-heading-1;
}
.header-description {
    @include ht-heading-4;
}
.wrapper-welcome-steps {
    width: 100%;
}
.welcome-steps {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.welcome-step {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    margin-bottom: 20px;
    border-radius: var(--radius-block);
    border: 1px solid var(--border-primary);
    background-color: var(--fill-primary);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03), 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
    .ht-emoji-icon {
        flex-shrink: 0;
        margin-right: 12px;
        border: 1px solid var(--border-primary);
        box-shadow: 0px 1px 3px 0px rgba(47, 43, 67, 0.10);
    }
}

.step-name {
    @include ht-body;
}

.step-progress {
    width: 90px;
    height: 26px;
    margin-left: auto;
    border-radius: 200px;
    background: repeating-linear-gradient(
        -45deg,
        var(--fill-primary),
        var(--fill-primary) 5px,
        #E8ECEB 5px,
        #E8ECEB 10px
    );
}

.current-progress {
    --progress: 0;
    width: calc(100% * var(--progress));
    height: 100%;
    border-radius: 200px;
    background-color: var(--fill-success);
    filter: drop-shadow(0px 0px 13px rgba(54, 255, 154, 0.40)) drop-shadow(0px 0px 4px rgba(83, 180, 131, 0.60));
}

@media (min-width: $tablet){
    .first-visit-popup {
        height: auto;
        padding: 48px;
        width: calc(100% - 40px);
        max-width: 1100px;
        border-radius: var(--radius-block);
    }
    .close-btn {
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .header-popup{
        max-width: 520px;
    }
}

@media (min-width: $tablet-large){
    .wrapper-welcome-steps {
        padding: 12px;
        margin-bottom: 32px;
        border-radius: var(--radius-block);
        background-color: var(--fill-secondary);

    }
    .welcome-steps {
        flex-direction: row;
        padding: 45px 65px 75px 25px;
        border-radius: var(--radius-block);
        border: 1px solid var(--border-primary);
        background: var(--fill-primary);
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03), 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
    }
    .welcome-step {
        position: relative;
        flex-direction: row-reverse;
        flex: 1 1 auto;
        margin-bottom: 0;
        padding: 0;
        border: 0;
        background-color: transparent;
        box-shadow: none;
        .ht-emoji-icon {
            margin-right: 0;
        }
    }

    .step-name {
        position: absolute;
        top: 60px;
        right: 25px;
        transform: translateX(50%);
    }

    .step-progress {
        flex: 1 1 auto;
        margin-left: 12px;
        margin-right: 12px;
    }
}

</style>
